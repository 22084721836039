/** @jsxImportSource @emotion/react */

import { useLocale } from '@/hooks/useLocal';
import { packagesLocale } from '@/locales/packages';
import * as Icon from '@heroicons/react/24/outline';
import { Text } from '@sal-solution/ui-web';
import 'twin.macro';
import { subscriptionLocales } from '../Subscription';
import { useSubscriptionQuery } from '../subscription.generated';

const numberToDate = (number?: number | string | null | undefined) => {
  if (!number) return '';
  if (typeof number === 'string') number = parseInt(number);
  const date = new Date(number);
  return date.toLocaleDateString();
};

export const SubscriptionInfo = () => {
  const locale = useLocale(subscriptionLocales);
  const packages = useLocale(packagesLocale);
  const {
    data,
    loading: subscriptionLoading,
    error: subscriptionError,
    refetch,
  } = useSubscriptionQuery();

  const error = subscriptionError;
  const loading = subscriptionLoading;

  return (
    <>
      {error && (
        <div role="alert" tw="alert alert-error w-full text-white mb-4">
          <button onClick={() => refetch()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              tw="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <span>{error.message}</span>
        </div>
      )}
      <Text tw="mb-4" as="h1" variant="title">
        {locale.title}: {data?.findMe?.subscription.stripeSubscriptionId}
      </Text>
      {data?.findMe?.subscription.package && (
        <Text tw="mb-4">
          <span tw="font-semibold">{locale.yourPackage}</span>:{' '}
          {packages[data?.findMe?.subscription.package]}
        </Text>
      )}
      {data?.findMe?.subscription?.package === 'medium' && (
        <Text tw="mb-4">
          <span tw="font-semibold">{locale.whatsAppMessagesSend}:</span>{' '}
          {data?.findMe?.subscription?.messages?.whatsAppMessagesSend || 0}
        </Text>
      )}
      <Text tw="mb-4 flex gap-1">
        <span tw="font-semibold">{locale.status}:</span>{' '}
        {data?.findMe?.subscription.isActive ? (
          <Icon.CheckCircleIcon tw="w-6 text-green-400" />
        ) : (
          <Icon.XCircleIcon tw="w-6 text-red-400" />
        )}
      </Text>
      <Text tw="mb-4">
        <span tw="font-semibold">{locale.since}:</span>{' '}
        {numberToDate(data?.findMe?.subscription.activatedAt)}
      </Text>
      {data?.findMe?.subscription.cancelledAt && (
        <Text tw="mb-4">
          {`${locale.endsOn}: ${numberToDate(
            Number(data.findMe.subscription.cancelledAt) * 1000
          )}`}
        </Text>
      )}
    </>
  );
};
