import { localeNL, localeEN } from '@/utils/locale';

export const packagesLocale = {
  [localeNL]: {
    monthlyCancel: 'Maandelijks opzegbaar',
    small: 'Email pakket',
    premium: 'Onbeperkt pakket',
    medium: 'WhatsApp startpakket',
  },
  [localeEN]: {
    monthlyCancel: 'Monthly cancelable',
    small: 'Email package',
    premium: 'Unlimited package',
    medium: 'WhatsApp starter package',
  },
};
