import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "next-mdx-import-source-file";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Terms and Conditions"
      })
    }), "\n", _jsx(_components.p, {
      children: "Date: April 22, 2024"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Article 1: Definitions"
      }), "\n1.1 'Subscription': the agreement regarding the use of the Platform that comes into effect between the Housing Seeker and RentWatchers when the Housing Seeker creates an account on ", _jsx(_components.a, {
        href: "https://rentwatcher.nl",
        children: "https://rentwatcher.nl"
      }), " and activates it through payment, as further described in these terms of use and on the Website;"]
    }), "\n", _jsx(_components.p, {
      children: "1.2 'Subscription Fee': the amount that must be paid to activate an account, as determined by RentWatchers from time to time. Changes in the Subscription Fee will not affect ongoing Subscriptions;"
    }), "\n", _jsx(_components.p, {
      children: "1.3 'Account': the free registration with RentWatchers through which a Housing Seeker receives examples but does not actively receive emails with links to recently posted rental properties;"
    }), "\n", _jsxs(_components.p, {
      children: ["1.4 'Landlord': a natural person or legal entity that offers a property for rent using the RentWatchers platform ", _jsx(_components.a, {
        href: "https://rentwatcher.nl",
        children: "https://rentwatcher.nl"
      }), " and/or via the email service of RentWatchers. This Landlord registration form provides the landlord the ability to also receive responses and viewing requests from subscribers;"]
    }), "\n", _jsxs(_components.p, {
      children: ["1.5 ‘", _jsx(_components.a, {
        href: "https://rentwatchers.nl%E2%80%99",
        children: "https://rentwatchers.nl’"
      }), ": Website of the private company with limited liability Sal Solutions, located in Amsterdam (Chamber of Commerce number 88529479; VAT number NL004620782B39);"]
    }), "\n", _jsx(_components.p, {
      children: "1.6 'Material': information about a living space that is offered for rent, supplied by or on behalf of landlords;"
    }), "\n", _jsx(_components.p, {
      children: "1.7 'Abuse': sending emails that ultimately infringe the rights or privacy of others, such as sending unsolicited commercial information and spam, making offensive or insulting statements, infringing intellectual property rights, or publicizing or reproducing Material without permission;"
    }), "\n", _jsx(_components.p, {
      children: "1.8 'Written': this also means by email and – in the case of communication by the Housing Seeker – via the contact form on the Website;"
    }), "\n", _jsxs(_components.p, {
      children: ["1.9 'Website': the website ", _jsx(_components.a, {
        href: "https://rentwatchers.nl",
        children: "https://rentwatchers.nl"
      }), ";"]
    }), "\n", _jsx(_components.p, {
      children: "1.10 'Housing Seeker': any natural person or legal entity who registers on the Website with the goal of finding a property. In these terms, the Housing Seeker is also referred to as 'you' or 'your';"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Article 2: Applicability"
      }), "\n2.1 These terms of use apply to your account as a housing seeker or as a landlord if you wish to use the RentWatchers platform."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Article 3: Function of the platform and rentwatchers"
      }), "\n3.1 RentWatchers offers a platform on the Website where Landlords can offer rental properties via advertisements (via email) and where Housing Seekers (i) are informed about available rental properties (ii) can create a profile of the living space they are looking for (iii) and can be informed by email about properties that are offered on third-party websites;"]
    }), "\n", _jsx(_components.p, {
      children: "3.2 RentWatchers merely has a 'bulletin board function' and has no further involvement in whether or not agreements between Housing Seekers and Landlords are concluded;"
    }), "\n", _jsx(_components.p, {
      children: "3.3 You understand and accept that subscribing does not necessarily mean that you will indeed find living space;"
    }), "\n", _jsxs(_components.p, {
      children: ["3.4 RentWatchers is not responsible for the content of the information about properties sent via email and cannot guarantee that the living space is actually available or that the information in the advertisement is correct and complete. If at any time you encounter an advertisement that you know or suspect is not current, correct, and/or complete, we ask you to report this as soon as possible via ", _jsx(_components.a, {
        href: "mailto:support@rentwatchers.com",
        children: "support@rentwatchers.com"
      }), ". We will then contact the relevant Landlord as soon as possible to further investigate this."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Article 4: Establishment of the Subscription"
      }), "\n4.1 The Subscription between you and RentWatchers is established after you have registered on the Website, paid the subscription fee, and RentWatchers has confirmed this registration by email."]
    }), "\n", _jsx(_components.p, {
      children: "4.2 Mandate Acceptance: By paying the subscription fee, you accept this mandate and give Sal Solutions and Stripe (our payment service provider) permission to send instructions to your bank to debit your account in accordance with those instructions. You have the right to a refund from your bank under the General Terms and Conditions that apply at your bank. A refund must be done within 8 weeks from the debit date."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Article 5: Costs"
      }), "\n5.1 As a housing seeker, several subscriptions are available. Currently, the price for a subscription of 1 month is €29.95, for 2 months €39.95, and for 3 months €49.95. After the initial period as described above, the subscription will automatically renew, each time for the same period as initially chosen. The automatic renewal ends automatically when you unsubscribe from the service or when you turn off the automatic renewal in your account;"]
    }), "\n", _jsx(_components.p, {
      children: "5.2 Landlord – You can use RentWatchers for free to offer your property to the housing seekers on RentWatchers. RentWatchers does not mediate in this, but only provides the platform through which communication"
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
