/** @jsxImportSource @emotion/react */

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  EnvelopeIcon,
  HomeIcon,
  KeyIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Button, Input } from '@sal-solution/ui-web';
import { FC } from 'react';
import 'twin.macro';
import { useLocale } from '../../../hooks/useLocal';
import { steps } from '../hooks/useSignup';
import { locales } from '../locale/signUpLocale';

type UserInformationProps = {
  onNextStep: (e: steps) => void;
};

export const UserInformation: FC<UserInformationProps> = ({ onNextStep }) => {
  const locale = useLocale(locales);

  return (
    <>
      <Input
        iconLeft={<UserIcon />}
        placeholder={locale.FirstName}
        name="firstName"
      />
      <Input
        iconLeft={<LockClosedIcon />}
        placeholder={locale.LastName}
        name="lastName"
      />
      <Input
        iconLeft={<EnvelopeIcon />}
        placeholder={locale.Email}
        name="email"
      />
      <Input iconLeft={<PhoneIcon />} placeholder={locale.Phone} name="phone" />
      {/* <Input iconLeft={<HomeIcon />} placeholder={locale.City} name="city" /> */}
      <Input
        iconLeft={<KeyIcon />}
        type="password"
        placeholder={locale.Password}
        name="password"
      />
      <Input
        iconLeft={<KeyIcon />}
        type="password"
        placeholder={locale['Confirm Password']}
        name="confirmPassword"
      />
      <div tw="flex gap-4">
        <Button
          tw="flex items-center justify-center gap-2"
          type="button"
          onClick={() => onNextStep(1)}
        >
          <ArrowLeftIcon tw="w-4" />
          {locale.Back}
        </Button>
        <Button
          tw="flex items-center justify-center gap-2"
          type="button"
          onClick={() => onNextStep(3)}
        >
          {locale.Next}
          <ArrowRightIcon tw="w-4" />
        </Button>
      </div>
    </>
  );
};
