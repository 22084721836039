/** @jsxImportSource @emotion/react */

import { NextPage } from 'next';
import 'twin.macro';
import { PageLayout, Section } from '../../components';
import { useLocale } from '../../hooks/useLocal';
import TermsEN from './TermsEN.mdx';
import TermsNL from './TermsNL.mdx';
import { localeEN, localeNL } from '../../utils/locale';

export const TermsPage: NextPage = () => {
  const Locale = useLocale({ [localeNL]: TermsNL, [localeEN]: TermsEN });

  return (
    <PageLayout>
      <Section tw="px-4 lg:px-0">
        <div tw="prose lg:prose-xl">
          <Locale />
        </div>
      </Section>
    </PageLayout>
  );
};
