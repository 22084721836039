import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindMyInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindMyInvoicesQuery = { __typename?: 'Query', findMyInvoices: Array<{ __typename?: 'Invoice', _id: string, isChargedBack: boolean, isRefunded: boolean, isPaid: boolean, stripeInvoiceId: string, amount: number, createdAt: string } | null> };


export const FindMyInvoicesDocument = gql`
    query findMyInvoices {
  findMyInvoices {
    _id
    isChargedBack
    isRefunded
    isPaid
    stripeInvoiceId
    amount
    createdAt
  }
}
    `;

/**
 * __useFindMyInvoicesQuery__
 *
 * To run a query within a React component, call `useFindMyInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindMyInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<FindMyInvoicesQuery, FindMyInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyInvoicesQuery, FindMyInvoicesQueryVariables>(FindMyInvoicesDocument, options);
      }
export function useFindMyInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyInvoicesQuery, FindMyInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyInvoicesQuery, FindMyInvoicesQueryVariables>(FindMyInvoicesDocument, options);
        }
export function useFindMyInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyInvoicesQuery, FindMyInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyInvoicesQuery, FindMyInvoicesQueryVariables>(FindMyInvoicesDocument, options);
        }
export type FindMyInvoicesQueryHookResult = ReturnType<typeof useFindMyInvoicesQuery>;
export type FindMyInvoicesLazyQueryHookResult = ReturnType<typeof useFindMyInvoicesLazyQuery>;
export type FindMyInvoicesSuspenseQueryHookResult = ReturnType<typeof useFindMyInvoicesSuspenseQuery>;
export type FindMyInvoicesQueryResult = Apollo.QueryResult<FindMyInvoicesQuery, FindMyInvoicesQueryVariables>;