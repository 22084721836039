import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCheckoutUrlMutationVariables = Types.Exact<{
  input: Types.CreateCheckoutUrlInput;
}>;


export type CreateCheckoutUrlMutation = { __typename?: 'Mutation', createCheckoutUrl: string };


export const CreateCheckoutUrlDocument = gql`
    mutation createCheckoutUrl($input: CreateCheckoutUrlInput!) {
  createCheckoutUrl(input: $input)
}
    `;
export type CreateCheckoutUrlMutationFn = Apollo.MutationFunction<CreateCheckoutUrlMutation, CreateCheckoutUrlMutationVariables>;

/**
 * __useCreateCheckoutUrlMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutUrlMutation, { data, loading, error }] = useCreateCheckoutUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutUrlMutation, CreateCheckoutUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutUrlMutation, CreateCheckoutUrlMutationVariables>(CreateCheckoutUrlDocument, options);
      }
export type CreateCheckoutUrlMutationHookResult = ReturnType<typeof useCreateCheckoutUrlMutation>;
export type CreateCheckoutUrlMutationResult = Apollo.MutationResult<CreateCheckoutUrlMutation>;
export type CreateCheckoutUrlMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutUrlMutation, CreateCheckoutUrlMutationVariables>;