import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserSettingsQuery = { __typename?: 'Query', findMe?: { __typename?: 'User', settings: { __typename?: 'Settings', receiveUpdatesOnEmail: boolean, receiveUpdatesOnWhatsApp: boolean, receiveUpdatesOnTelegram: boolean }, lookingFor: { __typename?: 'LookingFor', city?: Array<string> | null, minRent?: number | null, maxRent?: number | null } } | null };


export const UserSettingsDocument = gql`
    query userSettings {
  findMe {
    settings {
      receiveUpdatesOnEmail
      receiveUpdatesOnWhatsApp
      receiveUpdatesOnTelegram
    }
    lookingFor {
      city
      minRent
      maxRent
    }
  }
}
    `;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsQuery(baseOptions?: Apollo.QueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
      }
export function useUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
        }
export function useUserSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
        }
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsSuspenseQueryHookResult = ReturnType<typeof useUserSettingsSuspenseQuery>;
export type UserSettingsQueryResult = Apollo.QueryResult<UserSettingsQuery, UserSettingsQueryVariables>;