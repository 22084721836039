import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindUserNameQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindUserNameQuery = { __typename?: 'Query', findMe?: { __typename?: 'User', name: string } | null };


export const FindUserNameDocument = gql`
    query findUserName {
  findMe {
    name
  }
}
    `;

/**
 * __useFindUserNameQuery__
 *
 * To run a query within a React component, call `useFindUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindUserNameQuery(baseOptions?: Apollo.QueryHookOptions<FindUserNameQuery, FindUserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserNameQuery, FindUserNameQueryVariables>(FindUserNameDocument, options);
      }
export function useFindUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserNameQuery, FindUserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserNameQuery, FindUserNameQueryVariables>(FindUserNameDocument, options);
        }
export function useFindUserNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindUserNameQuery, FindUserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindUserNameQuery, FindUserNameQueryVariables>(FindUserNameDocument, options);
        }
export type FindUserNameQueryHookResult = ReturnType<typeof useFindUserNameQuery>;
export type FindUserNameLazyQueryHookResult = ReturnType<typeof useFindUserNameLazyQuery>;
export type FindUserNameSuspenseQueryHookResult = ReturnType<typeof useFindUserNameSuspenseQuery>;
export type FindUserNameQueryResult = Apollo.QueryResult<FindUserNameQuery, FindUserNameQueryVariables>;