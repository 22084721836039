/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { Button, Form, Input, Text } from '@sal-solution/ui-web';
import { FC } from 'react';
import { useUserSettingsQuery } from './settings.generated';
import { useUpdateUserSearchSettingsMutation } from './updateUserSearchSettings.generated';
import { toast } from 'react-toastify';
import { useUpdateUserSettingsMutation } from './updateUserSettings.generated';
import Select from 'react-select';
import { cities } from '@sal-solution/utils';
import { useLocale } from '../../../../hooks/useLocal';
import { Toggle } from '../../../../components';
import { localeEN, localeNL } from '../../../../utils/locale';

export const Settings: FC = () => {
  const locale = useLocale(SettingsLocale);
  const {
    data,
    error: errorUserSettings,
    loading: loadingUserSettings,
    refetch,
  } = useUserSettingsQuery();

  const [updateUserSearchSettings, updateUserResponse] =
    useUpdateUserSearchSettingsMutation();
  const [
    updateUserNotificationSettings,
    updateUserNotificationSettingsResponse,
  ] = useUpdateUserSettingsMutation();

  const onSubmitNotifications = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const email = form.get('email') as string;
    const whatsapp = form.get('whatsapp') as string;
    const telegram = form.get('telegram') as string;

    const response = await updateUserNotificationSettings({
      variables: {
        input: {
          receiveUpdatesOnEmail: email === 'on',
          receiveUpdatesOnTelegram: telegram === 'on',
          receiveUpdatesOnWhatsApp: whatsapp === 'on',
        },
      },
    });
    if (response.data) {
      toast.success('Notification settings updated');
      await refetch();
    }
  };

  const onSubmitSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const minRent = form.get('min-rent') as string;
    const maxRent = form.get('max-rent') as string;
    const cities = form.getAll('cities').map((city) => city as string);
    if (cities.length === 0) {
      toast.error('Please select at least one city');
      return;
    }
    const response = await updateUserSearchSettings({
      variables: {
        input: {
          minRent: Number(minRent),
          maxRent: Number(maxRent),
          city: cities,
        },
      },
    });

    if (response.data) {
      toast.success('Search settings updated');
      await refetch();
    }
  };

  const error =
    errorUserSettings ||
    updateUserResponse.error ||
    updateUserNotificationSettingsResponse.error;
  const loading =
    loadingUserSettings ||
    updateUserResponse.loading ||
    updateUserNotificationSettingsResponse.loading;

  const citiesForSelect = cities.map((city) => ({ value: city, label: city }));
  const userCities = data?.findMe?.lookingFor.city?.map((city) => ({
    value: city,
    label: city,
  }));
  return (
    <div tw="w-full lg:(grid grid-cols-2 gap-x-4)">
      {error && (
        <div role="alert" tw="alert alert-error w-full text-white mb-4">
          <button onClick={() => refetch()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              tw="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <span>{error.message}</span>
        </div>
      )}
      <div tw="lg:(col-start-1 col-end-3)">
        <div tw="w-full flex justify-between">
          <Text as="h1" variant="title">
            {locale.title}
          </Text>
          <Button onClick={() => refetch()} type="button">
            {loading && (
              <span tw="loading loading-spinner loading-xs mr-4"></span>
            )}
            {locale.refresh}
          </Button>
        </div>
        <Text as="h2" variant="subTitle">
          {locale.subTitle}
        </Text>
      </div>
      {loadingUserSettings ? (
        <span tw="loading loading-spinner loading-md"></span>
      ) : (
        <Form
          tw="space-y-2 shadow-2xl rounded-2xl p-4 mt-4 w-full"
          onSubmit={onSubmitNotifications}
        >
          <Text variant="subTitle">Notification Settings</Text>
          <Toggle
            text={locale.receiveEmail}
            name="email"
            defaultChecked={data?.findMe?.settings.receiveUpdatesOnEmail}
          />
          <Toggle
            text={locale.receiveWhatsapp}
            name="whatsapp"
            defaultChecked={data?.findMe?.settings.receiveUpdatesOnWhatsApp}
          />
          <Toggle
            text={locale.receiveTelegram}
            name="telegram"
            defaultChecked={data?.findMe?.settings.receiveUpdatesOnTelegram}
            disabled
          />
          <Button>
            {loading && <span tw="loading loading-spinner loading-md"></span>}
            {locale.update}
          </Button>
        </Form>
      )}
      <Form
        tw="space-y-2 shadow-2xl rounded-2xl p-4 mt-4 w-full"
        onSubmit={onSubmitSearch}
      >
        <Text variant="subTitle">Search Settings</Text>
        <Text variant="subTitle" tw="w-full text-left">
          {locale.city}
        </Text>

        <Select
          defaultValue={userCities}
          isMulti
          isLoading={loading}
          name="cities"
          options={citiesForSelect}
          className="basic-multi-select"
          classNamePrefix="select"
        />

        <Input
          label={locale.minRent}
          name="min-rent"
          placeholder={locale.minRent}
          defaultValue={
            data?.findMe?.lookingFor?.minRent
              ? String(data?.findMe?.lookingFor?.minRent)
              : undefined
          }
        />
        <Input
          label={locale.maxRent}
          name="max-rent"
          placeholder={locale.maxRent}
          defaultValue={
            data?.findMe?.lookingFor?.maxRent
              ? String(data?.findMe?.lookingFor?.maxRent)
              : undefined
          }
        />
        <Button>
          {loading && <span tw="loading loading-spinner loading-md"></span>}
          {locale.update}
        </Button>
      </Form>
    </div>
  );
};

// create locales object with all locales for settings page
export const SettingsLocale = {
  [localeEN]: {
    refresh: 'Refresh',
    title: 'Settings',
    subTitle: 'Update your notification and search settings',
    notificationSettings: 'Notification preferences',
    searchSettings: 'Search Settings',
    receiveEmail: 'Receive notifications on email',
    receiveWhatsapp: 'Receive notifications on Whatsapp',
    receiveTelegram: 'Receive notifications on Telegram (coming soon)',
    city: 'City',
    regions: 'In which regions are you looking for a house?',
    minRent: 'Minimum Rent',
    maxRent: 'Maximum Rent',
    update: 'Update',
  },
  [localeNL]: {
    refresh: 'Verversen',
    title: 'Instellingen',
    subTitle: 'Wijzig uw notificatie- en zoekinstellingen',
    notificationSettings: 'Notificatie voorkeuren',
    searchSettings: 'Zoek instellingen',
    receiveEmail: 'Ontvang notificaties via email',
    receiveWhatsapp: 'Ontvang notificaties via Whatsapp',
    receiveTelegram:
      'Ontvang notificaties via Telegram (binnenkort beschikbaar)',
    city: 'Stad',
    regions: "In welke regio's zoek je een huis?",
    minRent: 'Minimum huur',
    maxRent: 'Maximum huur',
    update: 'Bijwerken',
  },
} as const;
