import { steps } from '@/consts/consts';
import {
  SubscribeContextType,
  useSubscribe,
} from '@/contexts/Subscribe.context';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useCreateCheckoutUrlMutation } from '../graphql/createCheckoutUrl.generated';
import { useCreateUserMutation } from '../graphql/createUser.generated';
import { sendGTMEvent } from '@next/third-parties/google';

const validateSubscriber = (
  subscriber: SubscribeContextType['newSubscriber']
) => {
  if (!subscriber?.name) {
    throw new Error('Name is required');
  }
  if (!subscriber?.email) {
    throw new Error('Email is required');
  }

  if (!subscriber?.phone) {
    throw new Error('Phone is required');
  }
  if (!subscriber?.city) {
    throw new Error('City is required');
  }
  if (!subscriber?.password) {
    throw new Error('Password is required');
  }
  if (!subscriber?.confirmPassword) {
    throw new Error('Confirm Password is required');
  }
  if (subscriber?.password !== subscriber?.confirmPassword) {
    throw new Error('Passwords do not match');
  }
};

export type steps = 1 | 2 | 3 | 4;

export const useSignup = () => {
  const [createCheckoutUrl, createCheckoutUrlResponse] =
    useCreateCheckoutUrlMutation();
  const [createUser, createUserResponse] = useCreateUserMutation();
  const [step, setStep] = useState<steps>(1);
  const { userPackage } = useSubscribe();
  const onNextStep = () => {
    setStep((prev) => (prev === 1 ? 2 : prev === 2 ? 3 : 1));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const firstName = formData.get('firstName') as string;
      const lastName = formData.get('lastName') as string;
      const email = formData.get('email') as string;
      const password = formData.get('password') as string;
      const confirmPassword = formData.get('confirmPassword') as string;
      const phone = formData.get('phone') as string;
      const city = (formData.get('city') as string) || 'Unknown';
      const minRent = (formData.get('min-rent') as string) || '0';
      const maxRent = formData.get('max-rent') as string;
      const cities = formData.getAll('cities').map((city) => city as string);
      const sendEmail = (formData.get('send-email') as string) || 'on';
      const whatsapp = (formData.get('whatsapp') as string) || 'on';
      // const telegram = formData.get('telegram') as string;

      if (cities.length === 0) {
        toast.error('Please select at least one city');
        return;
      }

      if (
        !firstName ||
        !lastName ||
        !email ||
        !password ||
        !confirmPassword ||
        !phone ||
        !city ||
        !userPackage
      ) {
        throw new Error('All fields are required');
      }

      const name = `${firstName} ${lastName}`;
      const newSubscriber = {
        name,
        email,
        password,
        confirmPassword,
        phone,
        city,
      };

      validateSubscriber(newSubscriber);

      const createUserResponse = await createUser({
        variables: {
          input: {
            email: newSubscriber.email,
            name: newSubscriber.name,
            password: newSubscriber.password,
            tel: newSubscriber.phone,
            city: newSubscriber.city,
            lookingFor: {
              minRent: Number(minRent),
              maxRent: Number(maxRent),
              city: cities,
            },
            settings: {
              receiveUpdatesOnEmail: sendEmail === 'on',
              receiveUpdatesOnWhatsApp: whatsapp === 'on',
            },
          },
        },
      });

      if (createUserResponse.errors)
        return toast.error(createUserResponse.errors[0].message);
      else if (createUserResponse.data?.createUser?._id)
        toast.success('User created successfully');

      const checkoutUrl = await createCheckoutUrl({
        variables: {
          input: {
            userId: createUserResponse.data?.createUser?._id,
            userPackage: userPackage,
          },
        },
      });
      if (checkoutUrl.errors) return toast.error(checkoutUrl.errors[0].message);

      //window.open(checkoutUrl.data?.createCheckoutUrl, '_blank');
      // go to url
      if (checkoutUrl.data?.createCheckoutUrl)
        window.location.href = checkoutUrl.data?.createCheckoutUrl;
    } catch (err) {
      toast.error(String(err));
      console.log('err');
    }
  };

  const onStep = (step: steps) => {
    sendGTMEvent({ event: 'step', value: `go-to-step-${step}` });
    setStep(step);
  };

  const loading =
    createCheckoutUrlResponse.loading || createUserResponse.loading;
  const error = createCheckoutUrlResponse.error || createUserResponse.error;

  return {
    step,
    loading,
    loadingUser: createUserResponse.loading,
    loadingCheckout: createCheckoutUrlResponse.loading,
    error,
    onNextStep,
    setStep: onStep,
    onSubmit,
  };
};
