/** @jsxImportSource @emotion/react */

import { useLocale } from '@/hooks/useLocal';
import {
  HeroSection,
  StepWithHighlight,
  highLightColors,
} from '@sal-solution/ui-web';
import { FC } from 'react';
import 'twin.macro';
import { homePageLocale } from '../locale/homePage.locale';
import tw from 'twin.macro';

export const HowItWorks: FC = () => {
  const locale = useLocale(homePageLocale);

  return (
    <HeroSection
      topVariant="Curved"
      bottomVariant="Curved"
      fill="#fff"
      contentsStyle={[tw`bg-blue-50`]}
    >
      <div tw="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full px-4">
        <StepWithHighlight
          countText="1"
          title={locale.step1Description}
          description={locale.step1Explanation}
          SpanColor={highLightColors[1]}
          highlightedText={locale.step1Highlight}
        />
        <StepWithHighlight
          countText="2"
          title={locale.step2Description}
          description={locale.step2Explanation}
          SpanColor={highLightColors[2]}
          highlightedText={locale.step2Highlight}
        />
        <StepWithHighlight
          countText="3"
          title={locale.step3Description}
          description={locale.step3Explanation}
          SpanColor={highLightColors[3]}
          highlightedText={locale.step3Highlight}
        />
      </div>
    </HeroSection>
  );
};
