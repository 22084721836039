/** @jsxImportSource @emotion/react */

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Button, Input, Text } from '@sal-solution/ui-web';
import { cities } from '@sal-solution/utils';
import { FC } from 'react';
import Select from 'react-select';
import 'twin.macro';
import { useLocale } from '../../../hooks/useLocal';
import { SettingsLocale } from '../../ProfilePage/tabs/Settings/Settings';
import { steps } from '../hooks/useSignup';
import { locales } from '../locale/signUpLocale';

type UserPreferanceProps = {
  onNextStep: (e: steps) => void;
};

export const UserPreferance: FC<UserPreferanceProps> = ({ onNextStep }) => {
  const locale = useLocale(SettingsLocale);
  const userLocale = useLocale(locales);
  const citiesForSelect = cities.map((city) => ({ value: city, label: city }));

  return (
    <>
      <Text variant="subTitle" tw="w-full text-left text-base">
        {locale.regions}
      </Text>
      <Select
        isMulti
        name="cities"
        options={citiesForSelect}
        className="cities-multi-select"
        classNamePrefix="select"
      />
      {/* <Input
        label={locale.minRent}
        name="min-rent"
        type="number"
        placeholder={locale.minRent}
      /> */}
      <Text variant="subTitle" tw="w-full text-left text-base">
        {locale.maxRent}
      </Text>
      <Select
        name="max-rent"
        options={rentOptions}
        className="max-rent-multi-select"
        classNamePrefix="select-max-rent"
        defaultValue={{ value: '2000', label: '€2000,-' }}
      />

      <Button
        tw="flex items-center justify-center gap-2"
        onClick={() => onNextStep(2)}
        type="button"
      >
        {userLocale.Next}
        <ArrowRightIcon tw="w-4" />
      </Button>
    </>
  );
};

const rentOptions = [
  {
    value: '500',
    label: '€500,-',
  },
  {
    value: '750',
    label: '€750,-',
  },
  {
    value: '1000',
    label: '€1000,-',
  },
  {
    value: '1250',
    label: '€1250,-',
  },
  {
    value: '1500',
    label: '€1500,-',
  },
  {
    value: '1750',
    label: '€1750,-',
  },
  {
    value: '2000',
    label: '€2000,-',
  },
  {
    value: '2250',
    label: '€2250,-',
  },
  {
    value: '2500',
    label: '€2500,-',
  },
  {
    value: '2750',
    label: '€2750,-',
  },
  {
    value: '3000',
    label: '€3000,-',
  },
  {
    value: '3250',
    label: '€3250,-',
  },
  {
    value: '3500',
    label: '€3500,-',
  },
  {
    value: '3750',
    label: '€3750,-',
  },
  {
    value: '4000',
    label: '€4000,-',
  },
  {
    value: '4250',
    label: '€4250,-',
  },
  {
    value: '4500',
    label: '€4500,-',
  },
  {
    value: '4750',
    label: '€4750,-',
  },
  {
    value: '5000',
    label: '€5000,-',
  },
];
