/** @jsxImportSource @emotion/react */

import * as Icons from '@heroicons/react/24/outline';
import { Button } from '@sal-solution/ui-web';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import 'twin.macro';
import tw from 'twin.macro';
import { PageLayout, Section } from '../../components';
import { client, useAppContext } from '../../contexts';
import { useCurrentLocale } from '../../hooks/useLocal';
import { deleteCookieToken } from '../../utils/cookies';
import { useFindUserNameQuery } from './findUserName.generated';
import { Houses } from './tabs/Houses/Houses';
import { Profile } from './tabs/Profile/Profile';
import { Settings } from './tabs/Settings/Settings';
import { Subscription } from './tabs/Subscription/Subscription';
import { NextPage } from 'next';
import { localeEN, localeNL } from '../../utils/locale';

const buttonStyle = tw`flex justify-center  lg:(justify-start gap-2)`;

const navigationItems = [
  {
    label: 'Profile',
    tab: 'profile',
    locales: {
      [localeEN]: 'Profile',
      [localeNL]: 'Profiel',
    },
    icon: <Icons.UserIcon tw="w-4" />,
  },
  {
    label: 'Subscription',
    tab: 'subscription',
    locales: {
      [localeEN]: 'Subscription',
      [localeNL]: 'Abonnement',
    },

    icon: <Icons.CreditCardIcon tw="w-4" />,
  },
  {
    label: 'Settings',
    tab: 'settings',
    locales: {
      [localeEN]: 'Settings',
      [localeNL]: 'Woonwensen',
    },

    icon: <Icons.CogIcon tw="w-4" />,
  },
  {
    label: 'Houses',
    tab: 'houses',
    locales: {
      [localeEN]: 'Houses',
      [localeNL]: 'Aanbod',
    },
    icon: <Icons.HomeIcon tw="w-4" />,
  },
] as const;

const renderTab = (tab: string) => {
  switch (tab) {
    case 'profile':
      return <Profile />;
    case 'subscription':
      return <Subscription />;
    case 'settings':
      return <Settings />;
    case 'houses':
      return <Houses />;
    default:
      return <Profile />;
  }
};

type tabType = 'profile' | 'subscription' | 'settings' | 'houses';

export const ProfilePage: NextPage = () => {
  const currentLocale = useCurrentLocale();
  const locale = ProfilePageLocale[currentLocale || localeNL];
  const [tab, setTab] = useState<tabType>('subscription');
  const { data } = useFindUserNameQuery();
  const router = useRouter();
  const { setIsSignedIn } = useAppContext();
  const signOut = async () => {
    deleteCookieToken();
    await client.clearStore();
    setIsSignedIn(false);
    router.push('/sign-in');
  };

  return (
    <PageLayout>
      <Section>
        <div tw="flex min-h-full w-full shadow-2xl">
          <aside tw="flex flex-col space-y-4 text-white  bg-slate-800 lg:(rounded-bl-lg rounded-tl-lg min-w-[200px]) p-4">
            <div className="avatar placeholder">
              <div tw="bg-neutral text-neutral-content rounded-full w-8 lg:w-16">
                <span tw="text-lg lg:text-xl">
                  {data?.findMe?.name.slice(0, 1)}
                </span>
              </div>
            </div>
            {navigationItems.map((item) => (
              <Button
                css={[buttonStyle]}
                key={item.tab}
                onClick={() => setTab(item.tab)}
              >
                {item.icon}{' '}
                <span tw="hidden lg:block">
                  {item.locales[currentLocale || localeNL]}
                </span>
              </Button>
            ))}
            <Button variant="danger" css={[buttonStyle]} onClick={signOut}>
              <Icons.ArrowLeftStartOnRectangleIcon tw="w-4" />
              <span tw="hidden lg:block">{locale.cta}</span>
            </Button>
          </aside>
          <div tw="p-4 w-full">{renderTab(tab)}</div>
        </div>
      </Section>
    </PageLayout>
  );
};

const ProfilePageLocale = {
  [localeEN]: {
    cta: 'Sign out',
  },
  [localeNL]: {
    cta: 'Uitloggen',
  },
};
