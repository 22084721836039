import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SubscriptionQuery = { __typename?: 'Query', findMe?: { __typename?: 'User', lookingFor: { __typename?: 'LookingFor', city?: Array<string> | null }, subscription: { __typename?: 'userSubscription', package?: Types.Packages | null, stripeSubscriptionId?: string | null, isActive: boolean, hasExpiredPayment: boolean, expiresAt?: string | null, activatedAt?: string | null, cancelledAt?: string | null, messages?: { __typename?: 'Messages', whatsAppMessagesSend?: number | null } | null }, invoices: Array<{ __typename?: 'Invoice', amount: number, createdAt: string, isChargedBack: boolean, isPaid: boolean, isRefunded: boolean } | null> } | null };


export const SubscriptionDocument = gql`
    query subscription {
  findMe {
    lookingFor {
      city
    }
    subscription {
      package
      stripeSubscriptionId
      isActive
      hasExpiredPayment
      expiresAt
      activatedAt
      cancelledAt
      messages {
        whatsAppMessagesSend
      }
    }
    invoices {
      amount
      createdAt
      isChargedBack
      isPaid
      isRefunded
    }
  }
}
    `;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
      }
export function useSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export function useSubscriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionSuspenseQueryHookResult = ReturnType<typeof useSubscriptionSuspenseQuery>;
export type SubscriptionQueryResult = Apollo.QueryResult<SubscriptionQuery, SubscriptionQueryVariables>;