/** @jsxImportSource @emotion/react */

import { Button, Form, Input } from '@sal-solution/ui-web';
import { NextPage } from 'next';
import { toast } from 'react-toastify';
import 'twin.macro';
import { PageLayout, Section } from '../../components';
import { useLocale } from '../../hooks/useLocal';
import { localeEN, localeNL } from '../../utils/locale';
import { useRequestPasswordResetMutation } from './requestPasswordReset.generated';

export const PasswordForgetPage: NextPage = () => {
  const locale = useLocale(PasswordForgetPageLocale);
  const [request, { loading, error, data }] = useRequestPasswordResetMutation();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const email = form.get('email') as string;
    console.log('email', email);
    if (!email) return;
    try {
      const response = await request({ variables: { email } });
      console.log('response', response);
      toast.success('Password reset email sent successfully');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <PageLayout>
      <Section tw="flex-col px-4 lg:px-0">
        {error && (
          <div role="alert" tw="alert alert-error w-full text-white mb-4">
            <span>{error.message}</span>
          </div>
        )}
        {data && (
          <div role="alert" tw="alert alert-success w-full text-white mb-4">
            <span>{data.requestPasswordReset}</span>
          </div>
        )}

        <Form
          tw="space-y-4 shadow-2xl px-8 py-4 rounded-xl w-full"
          onSubmit={onSubmit}
        >
          <Input label="Email" name="email" type="email" placeholder="Email" />
          <Button type="submit" loading={loading}>
            {locale.cta}
          </Button>
        </Form>
      </Section>
    </PageLayout>
  );
};

const PasswordForgetPageLocale = {
  [localeEN]: {
    title: 'Password Forget',
    label: 'Email',
    cta: 'Request Reset Password',
  },
  [localeNL]: {
    title: 'Wachtwoord vergeten',
    label: 'Email',
    cta: 'Reset wachtwoord aanvragen',
  },
};
