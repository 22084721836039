/** @jsxImportSource @emotion/react */

import { Houses, columStyle } from '@/components';
import { HeroSection } from '@sal-solution/ui-web';
import { FC } from 'react';
import 'twin.macro';
import tw from 'twin.macro';
export const LatestHouses: FC = () => {
  return (
    <HeroSection
      tw="min-h-[65vh] px-4"
      // topFill="rgb(239 246 255)"
      // bottomFill="rgb(239 246 255)"
    >
      <div css={[columStyle, tw`w-full`]}>
        <Houses />
      </div>
    </HeroSection>
  );
};
