/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { columStyle } from '@/components';
import { useLocale } from '@/hooks/useLocal';
import { validateLocale } from '@/utils/locale';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Button, HeroSection, Text } from '@sal-solution/ui-web';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { FC } from 'react';

import { useNavigateAndTrack } from '@/hooks/useNavigateAndTrack';
import tw from 'twin.macro';
import selfieImage from '../assets/housing-selfie-md.jpg';
import { homePageLocale } from '../locale/homePage.locale';

export const Hero: FC = () => {
  const locale = useLocale(homePageLocale);

  const pathname = usePathname();
  const localeInPath = validateLocale(pathname.split('/')[1]);

  const route = useNavigateAndTrack();

  return (
    <HeroSection
      fill="rgb(255 251 235)"
      contentsStyle={[tw`bg-white`]}
      tw="text-black min-h-[60vh] 2xl:(min-h-[50vh])"
      bottomVariant="Wave"
    >
      <div
        css={[columStyle]}
        tw="flex justify-between items-center h-full w-full px-4 py-8 relative flex-col lg:(grid-cols-3 items-start gap-4 px-0 py-4 flex-row)"
      >
        <div tw="h-full flex flex-col justify-center space-y-4 relative z-10">
          <Text
            as="h1"
            variant="title"
            tw="text-5xl lg:(text-6xl max-w-[700px])"
          >
            {locale.title}
          </Text>
          <div>
            {/* <Text as="h2" variant="subTitle">
              {locale.subTitle}
            </Text> */}
            <Text tw=" lg:max-w-[500px]">{locale.description}</Text>
          </div>
          <div tw="flex gap-4">
            <Button
              variant="success"
              tw="flex items-center"
              onClick={() => route('/sign-up', 'hero-now-sign-up')}
              locale={localeInPath}
            >
              <MagnifyingGlassIcon className="w-4" tw="w-4" /> {locale.notify}
            </Button>
          </div>
        </div>
        <div tw=" w-full h-full mt-8 lg:(mt-0 static shadow-md w-1/2 min-h-[300px] object-cover bg-black bg-transparent)">
          <Image
            tw="w-full h-full object-cover rounded-xl shadow-md lg:(opacity-100)"
            src={selfieImage.src}
            width={selfieImage.width}
            height={selfieImage.height}
            alt="appartment"
          />
        </div>
      </div>
    </HeroSection>
  );
};
