import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "next-mdx-import-source-file";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Algemene Voorwaarden"
      })
    }), "\n", _jsx(_components.p, {
      children: "Datum: 22 april 2024"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 1: Definities"
      }), "\n1.1 'Abonnement': de overeenkomst betreffende het gebruik van het Platform die tot stand komt tussen de Woningzoekende en RentWatchers wanneer de Woningzoekende een account aanmaakt op ", _jsx(_components.a, {
        href: "https://rentwatcher.nl",
        children: "https://rentwatcher.nl"
      }), " en deze activeert door middel van betaling, zoals nader omschreven in deze gebruiksvoorwaarden en op de Website;"]
    }), "\n", _jsx(_components.p, {
      children: "1.2 'Abonnementsgeld': het bedrag dat betaald moet worden om een account te activeren, zoals van tijd tot tijd door RentWatchers wordt vastgesteld. Wijzigingen in het Abonnementsgeld hebben geen invloed op lopende Abonnementen;"
    }), "\n", _jsx(_components.p, {
      children: "1.3 'Account': de gratis inschrijving bij RentWatchers waarmee een Woningzoekende voorbeelden toegestuurd krijgt maar niet actief e-mails ontvangt met links naar recent geplaatste huurwoningen;"
    }), "\n", _jsxs(_components.p, {
      children: ["1.4 'Verhuurder': natuurlijk persoon of rechtspersoon die een woning te huur aanbiedt via het RentWatchers platform ", _jsx(_components.a, {
        href: "https://rentwatcher.nl",
        children: "https://rentwatcher.nl"
      }), " en/of via de e-maildienst van RentWatchers. Deze Verhuurder-registratievorm biedt de verhuurder de mogelijkheid om ook reacties en bezichtigingsaanvragen van abonnees te ontvangen;"]
    }), "\n", _jsxs(_components.p, {
      children: ["1.5 '", _jsx(_components.a, {
        href: "https://rentwatchers.nl",
        children: "https://rentwatchers.nl"
      }), "': Website van de besloten vennootschap met beperkte aansprakelijkheid Sal Solutions, gevestigd in Amsterdam (KvK-nummer 88529479; BTW-nummer NL004620782B39);"]
    }), "\n", _jsx(_components.p, {
      children: "1.6 'Materiaal': door of namens verhuurders aangeleverde informatie over een woonruimte die te huur wordt aangeboden;"
    }), "\n", _jsx(_components.p, {
      children: "1.7 'Misbruik': het versturen van e-mails waarmee de rechten of privacy van anderen (uiteindelijk) worden geschonden, zoals het versturen van ongevraagde commerciële informatie en spam, het doen van grievende of beledigende uitspraken, inbreuk maken op intellectuele eigendomsrechten of het openbaar maken of verveelvoudigen van Materiaal zonder toestemming;"
    }), "\n", _jsx(_components.p, {
      children: "1.8 'Schriftelijk': hieronder wordt ook verstaan per e-mail en – in het geval van communicatie door Woningzoekende – via het contactformulier op de Website;"
    }), "\n", _jsxs(_components.p, {
      children: ["1.9 'Website': de website ", _jsx(_components.a, {
        href: "https://rentwatchers.nl",
        children: "https://rentwatchers.nl"
      }), ";"]
    }), "\n", _jsx(_components.p, {
      children: "1.10 'Woningzoekende': iedere natuurlijke persoon of rechtspersoon die zich op de Website inschrijft met als doel het vinden van een woning. In deze voorwaarden wordt de Woningzoekende ook aangeduid als 'je' of 'jij';"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 2: Toepasselijkheid"
      }), "\n2.1 Deze gebruiksvoorwaarden zijn van toepassing op jouw account als woningzoekende of als verhuurder indien je gebruik wilt maken van het RentWatchers platform."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 3: Functie van het platform en RentWatchers"
      }), "\n3.1 RentWatchers biedt op de Website een platform waar Verhuurders woonruimte te huur kunnen aanbieden via advertenties (via e-mail) en waar Woningzoekenden (i) geïnformeerd worden over beschikbare huurwoningen (ii) een profiel kunnen aanmaken van de woonruimte die zij zoeken (iii) en per e-mail geïnformeerd kunnen worden over woningen die worden aangeboden op websites van derden;"]
    }), "\n", _jsx(_components.p, {
      children: "3.2 RentWatchers heeft slechts een 'prikbordfunctie' en heeft geen verdere betrokkenheid bij het al dan niet tot stand komen van overeenkomsten tussen Woningzoekenden en Verhuurders;"
    }), "\n", _jsx(_components.p, {
      children: "3.3 Je begrijpt en accepteert dat het afsluiten van een Abonnement niet per se betekent dat je ook daadwerkelijk woonruimte zult vinden;"
    }), "\n", _jsx(_components.p, {
      children: "3.4 RentWatchers is niet verantwoordelijk voor de inhoud van de informatie over woningen die via e-mail wordt verzonden en kan niet garanderen dat de woonruimte daadwerkelijk beschikbaar is of dat de informatie in de advertentie juist en volledig is. Indien je op"
    }), "\n", _jsxs(_components.p, {
      children: ["enig moment een advertentie tegenkomt waarvan je weet of vermoedt dat deze niet actueel, juist en/of volledig is, verzoeken wij je dit zo spoedig mogelijk te melden via ", _jsx(_components.a, {
        href: "mailto:support@rentwatchers.nl",
        children: "support@rentwatchers.nl"
      }), ". Wij zullen dan zo spoedig mogelijk contact opnemen met de betreffende Verhuurder om dit verder te onderzoeken."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 4: Totstandkoming van het Abonnement"
      }), "\n4.1 Het Abonnement tussen jou en RentWatchers komt tot stand nadat je je via de Website hebt aangemeld, het abonnementsgeld hebt betaald en RentWatchers deze aanmelding per e-mail heeft bevestigd."]
    }), "\n", _jsx(_components.p, {
      children: "4.2 Mandaatacceptatie: Door het abonnementsgeld te betalen accepteer je deze mandaat en geef je Sal Solutions en Stripe (onze betaaldienstaanbieder) toestemming om instructies naar je bank te sturen om je rekening te debiteren in overeenstemming met die instructies. Je hebt recht op een retour van je bank volgens de Algemene Voorwaarden die gelden bij je bank. Een retour moet binnen 8 weken van de afschrijving geschieden."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 5: Kosten"
      }), "\n5.1 Als woningzoekende zijn er verschillende abonnementen beschikbaar. Op dit moment is de prijs voor een abonnement van 1 maand €29,95, van 2 maanden €39,95 en van 3 maanden €49,95. Na de initiatieve periode zoals hierboven omschreven, zal het abonnement automatisch worden verlengd, telkens voor dezelfde periode als initieel gekozen. De automatische verlenging eindigt automatisch wanneer je je afmeldt van de dienst of wanneer je de automatische verlenging uitzet in je account;"]
    }), "\n", _jsx(_components.p, {
      children: "5.2 Verhuurder – Je kunt RentWatchers gratis gebruiken om je woning aan te bieden aan de woningzoekers op RentWatchers. RentWatchers bemiddelt hierin niet, maar verzorgt alleen het platform waarlangs communicatie tot stand kan komen tussen verhuurder en woningzoekende. Andere diensten, zoals bijvoorbeeld bezichtigingen, zijn niet van toepassing;"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 6: Herroepingsrecht"
      }), "\n6.1 Indien je als woningzoekende met een betaald abonnement niet tevreden bent met de manier waarop ons platform voor jou heeft gepresteerd, dan kun je het betaalde abonnementsgeld retour krijgen. Stuur ons hiervoor binnen 14 dagen na aanvang van je abonnement een e-mail naar ", _jsx(_components.a, {
        href: "mailto:support@rentwatcher.nl",
        children: "support@rentwatcher.nl"
      }), " met opgaaf van reden;"]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 7: Opzeggen"
      }), "\n7.1 Je kunt je abonnement op ieder moment opzeggen. RentWatchers zal tijdig voor het einde van een periode aankondigen dat de huidige periode binnenkort eindigt en hierbij, voor het gemak, een link toevoegen naar de pagina waar het abonnement beëindigd kan worden, dus je loopt geen risico indien je het abonnement \"vergeet\" op te zeggen wanneer je het platform niet meer nodig hebt;"]
    }), "\n", _jsx(_components.p, {
      children: "7.2 Opzegging van je abonnement kan online via je account door naar 'Mijn RentWatchers' te gaan en daar je account op te zeggen door op de knop \"afmelden\" te drukken."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 8: Betalingen"
      }), "\n8.1 Voor Woningzoekenden wordt het Abonnementsgeld door middel van betaling via iDeal, Creditcard, Google Pay, Apple Pay of Sepa automatische incasso voldaan;"]
    }), "\n", _jsx(_components.p, {
      children: "8.2 Voor Verhuurders – Gebruik is gratis;"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 9: Wet- en regelgeving"
      }), "\n9.1 Woningzoekende en Aanbieder dienen zich bij het gebruik van de Website en het eventueel sluiten van overeenkomsten die daaruit voort kunnen vloeien, te houden aan alle toepasselijke wet- en regelgeving. Zonder af te doen aan het vorengaande, verklaren zij bekend te zijn met de relevante lokale wet- en/of regelgeving, zoals met betrekking tot woning- en kamerbemiddelingsbureaus."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 10: Misbruik"
      }), "\n10.1 Bij Misbruik door een Woningzoek"]
    }), "\n", _jsx(_components.p, {
      children: "ende kan RentWatchers diens Abonnement met onmiddellijke ingang beëindigen door Woningzoekende hierover per e-mail te informeren;"
    }), "\n", _jsx(_components.p, {
      children: "10.2 RentWatchers is gerechtigd zonder nadere ingebrekestelling een direct opeisbare boete van € 1.000,00 (zegge: duizend euro) per feit van misbruik aan de Woningzoekende in rekening te brengen, waarbij het misbruik van (persoons)gegevens, foto- of ander Materiaal gezamenlijk geldt als één feit, onverminderd het recht van RentWatchers om nadere rechtsmaatregelen tegen de Woningzoekende te nemen en vergoeding te vorderen van schade die is ontstaan als gevolg van misbruik door de Woningzoekende."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 11: Verbod op Zakelijk Gebruik"
      }), "\n11.1 De dienst wordt uitsluitend aangeboden voor gebruik door particulieren. Het is bedrijven, organisaties, instellingen of enige andere juridische entiteiten, hierna te noemen “Zakelijke Gebruikers”, niet toegestaan om de dienst te gebruiken voor enige zakelijke, commerciële of professionele doeleinden zonder voorafgaande schriftelijke toestemming van de Dienstverlener.;"]
    }), "\n", _jsx(_components.p, {
      children: "11.2 Zakelijke Gebruikers die dienen voorafgaand aan enig gebruik contact op te nemen met de Dienstverlener om een aparte overeenkomst te sluiten die het zakelijke gebruik van de Dienst reguleert. De voorwaarden van een dergelijke overeenkomst, inclusief maar niet beperkt tot vergoedingen, gebruiksrechten, en duur, zullen onderling overeengekomen worden tussen de Dienstverlener en de Zakelijke Gebruiker.;"
    }), "\n", _jsx(_components.p, {
      children: "11.3 Bij constatering van ongeautoriseerd gebruik van de Dienst door een Zakelijke Gebruiker zonder de vereiste voorafgaande schriftelijke toestemming, zal een geldboete opgelegd worden aan de betreffende Zakelijke Gebruiker. De geldboete bedraagt €4500 (vierduizend vijfhonderd euro) per maand dat het ongeautoriseerde gebruik voortduurt, onverminderd het recht van de Dienstverlener om verdere juridische stappen te ondernemen en volledige schadevergoeding te eisen voor geleden verliezen en gemaakte kosten.;"
    }), "\n", _jsx(_components.p, {
      children: "11.4 Door gebruik te maken van de Dienst, erkennen gebruikers dat zij kennis hebben genomen van deze voorwaarden en hiermee expliciet akkoord gaan.;"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 12: Aansprakelijkheid"
      }), "\n12.1 Op het moment dat een woningzoekende – om welke reden dan ook – schade lijdt als gevolg van het gebruik van de website RentWatchers en/of het Abonnement, kan RentWatchers in geen enkel geval aansprakelijk worden gesteld voor de door de Woningzoekende geleden schade;"]
    }), "\n", _jsx(_components.p, {
      children: "12.2 Indien en voor zover een gerechtelijke instantie niettemin oordeelt dat RentWatchers aansprakelijk is voor enige schade die door de Woningzoekende wordt geleden en dat RentWatchers gehouden is om de schade geheel of gedeeltelijk te vergoeden, zal de totale aansprakelijkheid van RentWatchers jegens de Woningzoekende (waaronder wordt begrepen een verplichting om het Abonnementsgeld terug te betalen) nooit meer bedragen dan het van die Woningzoekende ontvangen Abonnementsgeld;"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 13: Privacybeleid"
      }), "\nKlik hier voor ons privacybeleid."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 14: Verzoeken/Toestemming"
      }), "\n14.1 Als je na het afsluiten van het Abonnement een afwijking en/ of aanvulling van enige bepaling van deze voorwaarden of andere voorwaarden verlangt, dan kun je schriftelijk een verzoek bij ons indienen;"]
    }), "\n", _jsx(_components.p, {
      children: "14.2 Indien en voor zover in enige bepaling van deze gebruiksvoorwaarden de toestemming van RentWatchers wordt vereist, wordt deze alleen dan geacht te zijn verleend indien deze schriftelijk is verstrekt;"
    }), "\n", _jsx(_components.p, {
      children: "14.3 Een door RentWatchers gegeven toestemming is eenmalig"
    }), "\n", _jsx(_components.p, {
      children: "en geldt niet voor andere of opvolgende gevallen. RentWatchers is gerechtigd om aan zijn toestemming voorwaarden te verbinden."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 15: Tussentijdse Beëindiging, in Gebreke Zijn"
      }), "\n15.1 Zonder afbreuk te doen aan de overige rechten van RentWatchers op grond van deze gebruiksvoorwaarden en de wet (waaronder begrepen het recht op nakoming en eventuele rechten om het Abonnement te ontbinden), heeft RentWatchers het recht om jouw Abonnement met onmiddellijke ingang zonder nadere ingebrekestelling te beëindigen door middel van een schriftelijke kennisgeving aan jou indien jij:\n(a) de door jou verschuldigde bedragen niet op de gestelde tijdstippen voldoet;\n(b) enige andere bepaling van de deze gebruiksvoorwaarden niet nakomt;\n(c) enige voorwaarde, verbonden aan een door RentWatchers verleende toestemming, niet in acht neemt;\n(d) een aanvraag tot surseance van betaling hebt ingediend en/of hebt verzocht om toelating tot de Wet Schuldsanering Natuurlijke Personen;\n(e) de vrije beschikking over jouw vermogen of een deel daarvan verliest;\n(f) in staat van faillissement wordt verklaard;\n(g) een akkoord buiten faillissement aanbiedt of indien beslag wordt gelegd op goederen van jou;\n(h) komt te overlijden; of\n(i) als Woningzoekende geen natuurlijk persoon is, zij haar rechtspersoonlijkheid verliest, wordt ontbonden of feitelijk wordt geliquideerd."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 16: Bestrijding Illegale Verhuur"
      }), "\n16.1 Om woningzoekenden te beschermen tegen illegaal aanbod van huurwoningen, is het verhuurders verboden om advertenties aan te bieden met de volgende strekking:"]
    }), "\n", _jsx(_components.p, {
      children: "16.2 Wel wonen, niet inschrijven. Het is verboden om een woonruimte aan te bieden zonder dat de bewoner zich op dit adres kan of mag inschrijven bij de gemeente. En/of:"
    }), "\n", _jsx(_components.p, {
      children: "16.3 Niet wonen, wel inschrijven. Het is verboden om een woonadres aan te bieden waarvan het niet de bedoeling is om in te wonen, maar de huurder zich wel op dit adres mag inschrijven bij de gemeente."
    }), "\n", _jsx(_components.p, {
      children: "16.4 Verhuurder verklaart dat de woning een vrije sector woning betreft."
    }), "\n", _jsx(_components.p, {
      children: "16.5 Verhuurder verklaart, indien er een hypotheek op de woning is afgesloten, dat er door de hypotheekverstrekker toestemming is verleend om de woning te verhuren."
    }), "\n", _jsxs(_components.p, {
      children: ["16.6 Deze eisen komen voort uit de Wet Basisregistratie Personen (BRP), te vinden op ", _jsx(_components.a, {
        href: "https://wetten.overheid.nl/BWBR0033715/2014-01-06",
        children: "https://wetten.overheid.nl/BWBR0033715/2014-01-06"
      }), ". Als wij constateren dat een advertentie het illegaal aanbod van een woonruimte betreft, zullen wij de betreffende verhuurder sommeren om de advertentie per direct te verwijderen. Bij een dergelijke overtreding geven wij de advertentie en de contactgegevens van de verhuurder altijd door aan het bevoegde ministerie."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Artikel 17: Toepasselijk Recht en Bevoegde Rechter"
      }), "\n17.1 Op het Abonnement en alle bovenstaande artikelen is Nederlands recht van toepassing."]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
