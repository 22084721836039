export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  _Any: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Change = {
  __typename?: 'Change';
  _id: Scalars['ID']['output'];
  field: Scalars['String']['output'];
  value: ChangeValue;
};

export type ChangeInput = {
  field: Scalars['String']['input'];
  value: ChangeValueInput;
};

export type ChangeValue = {
  __typename?: 'ChangeValue';
  _id: Scalars['ID']['output'];
  contacts?: Maybe<Array<Maybe<Contact>>>;
  messages?: Maybe<Array<Maybe<WaMessage>>>;
  messaging_product: Scalars['String']['output'];
  metadata: Metadata;
  statuses?: Maybe<Array<Maybe<Status>>>;
};

export type ChangeValueInput = {
  contacts?: InputMaybe<Array<InputMaybe<WaContactInput>>>;
  messages?: InputMaybe<Array<InputMaybe<MessageInput>>>;
  messaging_product: Scalars['String']['input'];
  metadata: MetadataInput;
  statuses?: InputMaybe<Array<InputMaybe<StatusInput>>>;
};

export type Contact = {
  __typename?: 'Contact';
  _id: Scalars['ID']['output'];
  profile: Profile;
  wa_id: Scalars['String']['output'];
};

export type ContactInput = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['String']['output'];
  origin: Origin;
};

export type ConversationContact = {
  __typename?: 'ConversationContact';
  name?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
};

export type ConversationInput = {
  id: Scalars['String']['input'];
  origin: OriginInput;
};

export type ConversationMessages = {
  __typename?: 'ConversationMessages';
  hasMore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  messages: Array<Message>;
  offset: Scalars['Int']['output'];
};

export type CreateCheckoutUrlInput = {
  userId?: InputMaybe<Scalars['ID']['input']>;
  userPackage?: InputMaybe<Packages>;
};

export type CreateUserInput = {
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  lookingFor?: InputMaybe<UpdateUserSearchSettingsInput>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  settings?: InputMaybe<UpdateUserSettingsInput>;
  tel: Scalars['String']['input'];
};

export type Entry = {
  __typename?: 'Entry';
  _id: Scalars['ID']['output'];
  changes: Array<Change>;
  id: Scalars['String']['output'];
};

export type EntryInput = {
  changes: Array<ChangeInput>;
  id: Scalars['String']['input'];
};

export type ImageMessage = {
  __typename?: 'ImageMessage';
  id?: Maybe<Scalars['String']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  sha256?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ImageMessageInput = {
  id: Scalars['String']['input'];
  mime_type: Scalars['String']['input'];
  sha256: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  _id: Scalars['ID']['output'];
  amount: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  isChargedBack: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  isRefunded: Scalars['Boolean']['output'];
  stripeInvoiceId: Scalars['String']['output'];
};

export type LookingFor = {
  __typename?: 'LookingFor';
  city?: Maybe<Array<Scalars['String']['output']>>;
  maxRent?: Maybe<Scalars['Int']['output']>;
  minRent?: Maybe<Scalars['Int']['output']>;
};

export type Message = {
  __typename?: 'Message';
  _id: Scalars['ID']['output'];
  body: MessageBody;
  createdAt: Scalars['String']['output'];
  metadata?: Maybe<MetaData>;
  receiver: Receiver;
  sender: Sender;
  updatedAt: Scalars['String']['output'];
};

export type MessageBody = {
  __typename?: 'MessageBody';
  _id: Scalars['ID']['output'];
  audio?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  video?: Maybe<Scalars['String']['output']>;
};

export type MessageConversation = {
  __typename?: 'MessageConversation';
  id: Scalars['String']['output'];
  origin: Origin;
};

export type MessageInput = {
  from: Scalars['String']['input'];
  id: Scalars['String']['input'];
  image?: InputMaybe<ImageMessageInput>;
  text?: InputMaybe<TextMessageInput>;
  timestamp: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type MessageOrigin = {
  __typename?: 'MessageOrigin';
  type: Scalars['String']['output'];
};

export type MessagePricing = {
  __typename?: 'MessagePricing';
  billable: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  pricing_model: Scalars['String']['output'];
};

export type Messages = {
  __typename?: 'Messages';
  lastMessageSendAt?: Maybe<Scalars['String']['output']>;
  whatsAppMessagesSend?: Maybe<Scalars['Int']['output']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  _id: Scalars['ID']['output'];
  audioId?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['ID']['output']>;
  conversation: MessageConversation;
  documentId?: Maybe<Scalars['String']['output']>;
  entryId?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  pricing: MessagePricing;
  status?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  display_phone_number: Scalars['String']['output'];
  phone_number_id: Scalars['String']['output'];
};

export type MetadataInput = {
  display_phone_number: Scalars['String']['input'];
  phone_number_id: Scalars['String']['input'];
};

export type MetricsFilters = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addImageToMessage: Scalars['String']['output'];
  addVideoToMessage: Scalars['String']['output'];
  cancelSubscription: Scalars['Boolean']['output'];
  contact: Scalars['String']['output'];
  createCheckoutUrl: Scalars['String']['output'];
  createUser?: Maybe<User>;
  deleteUser: Scalars['String']['output'];
  login?: Maybe<AuthPayload>;
  requestPasswordReset: Scalars['String']['output'];
  resetPassword: Scalars['String']['output'];
  sendMedia: Scalars['String']['output'];
  sendMessage: Scalars['String']['output'];
  sendTemplateMessage: Scalars['String']['output'];
  sendWhatsAppMessage: Scalars['String']['output'];
  updateUser?: Maybe<User>;
  updateUserSearchSettings?: Maybe<User>;
  updateUserSettings?: Maybe<User>;
  visitHouse: Scalars['String']['output'];
};


export type MutationAddImageToMessageArgs = {
  imageId?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  messageWAId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddVideoToMessageArgs = {
  messageId?: InputMaybe<Scalars['String']['input']>;
  messageWAId?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationContactArgs = {
  input: ContactInput;
};


export type MutationCreateCheckoutUrlArgs = {
  input?: InputMaybe<CreateCheckoutUrlInput>;
};


export type MutationCreateUserArgs = {
  input?: InputMaybe<CreateUserInput>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendMediaArgs = {
  mediaId: Scalars['String']['input'];
  mediaType: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type MutationSendMessageArgs = {
  input: WhatsAppWebhookInput;
};


export type MutationSendTemplateMessageArgs = {
  input: TemplateInput;
  to: Scalars['String']['input'];
};


export type MutationSendWhatsAppMessageArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};


export type MutationUpdateUserSearchSettingsArgs = {
  input?: InputMaybe<UpdateUserSearchSettingsInput>;
};


export type MutationUpdateUserSettingsArgs = {
  input?: InputMaybe<UpdateUserSettingsInput>;
};


export type MutationVisitHouseArgs = {
  houseId: Scalars['ID']['input'];
};

export type Origin = {
  __typename?: 'Origin';
  type: Scalars['String']['output'];
};

export type OriginInput = {
  type: Scalars['String']['input'];
};

export type Pricing = {
  __typename?: 'Pricing';
  billable: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  pricing_model: Scalars['String']['output'];
};

export type PricingInput = {
  billable: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  pricing_model: Scalars['String']['input'];
};

export type Profile = {
  __typename?: 'Profile';
  name: Scalars['String']['output'];
};

export type ProfileInput = {
  name: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  facebookPixelHealthCheck?: Maybe<Scalars['String']['output']>;
  findConversation: ConversationMessages;
  findConversations: Array<Maybe<ConversationContact>>;
  findImage: Scalars['String']['output'];
  findMe?: Maybe<User>;
  findMessage?: Maybe<WhatsAppWebhook>;
  findMessages: Array<WhatsAppWebhook>;
  findMetrics?: Maybe<FindMetrics>;
  findMyInvoices: Array<Maybe<Invoice>>;
  findTemplateById: TemplateSingle;
  findTemplates: Array<Maybe<Template>>;
  findUser?: Maybe<User>;
  findUsers: Array<User>;
  findWhatsAppMessage: Scalars['String']['output'];
  houses: Array<House>;
  housesMetrics?: Maybe<HouseMetricsByPrice>;
  housesStats?: Maybe<HousesStats>;
  housesVisited: Array<House>;
  latestHouses: Array<House>;
};


export type QueryFindConversationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  receiver: Scalars['String']['input'];
  sender: Scalars['String']['input'];
};


export type QueryFindImageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFindMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFindMessagesArgs = {
  filters?: InputMaybe<WhatsAppWebhookFilters>;
};


export type QueryFindMetricsArgs = {
  filters?: InputMaybe<MetricsFilters>;
};


export type QueryFindTemplateByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFindWhatsAppMessageArgs = {
  messageId: Scalars['String']['input'];
};

export type Receiver = {
  __typename?: 'Receiver';
  _id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
};

export type Sender = {
  __typename?: 'Sender';
  _id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
};

export type Settings = {
  __typename?: 'Settings';
  receiveUpdatesOnEmail: Scalars['Boolean']['output'];
  receiveUpdatesOnTelegram: Scalars['Boolean']['output'];
  receiveUpdatesOnWhatsApp: Scalars['Boolean']['output'];
};

export type Status = {
  __typename?: 'Status';
  _id: Scalars['ID']['output'];
  conversation: Conversation;
  id: Scalars['String']['output'];
  pricing: Pricing;
  recipient_id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type StatusInput = {
  conversation: ConversationInput;
  id: Scalars['String']['input'];
  pricing: PricingInput;
  recipient_id: Scalars['String']['input'];
  status: Scalars['String']['input'];
  timestamp: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  houseAdded?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
  whatsAppWebhook?: Maybe<Scalars['String']['output']>;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type TemplateComponent = {
  __typename?: 'TemplateComponent';
  format?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type TemplateComponentInput = {
  parameters: Array<TemplateParameterInput>;
  type: Scalars['String']['input'];
};

export type TemplateDocumentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateImageInput = {
  link?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateInput = {
  components: Array<TemplateComponentInput>;
  language?: InputMaybe<TemplateLanguageInput>;
  name: Scalars['String']['input'];
};

export type TemplateLanguageInput = {
  code: Scalars['String']['input'];
};

export type TemplateParameterInput = {
  document?: InputMaybe<TemplateDocumentInput>;
  image?: InputMaybe<TemplateImageInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type TemplateSingle = {
  __typename?: 'TemplateSingle';
  category: Scalars['String']['output'];
  components?: Maybe<Array<Maybe<TemplateComponent>>>;
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type TextMessage = {
  __typename?: 'TextMessage';
  body?: Maybe<Scalars['String']['output']>;
};

export type TextMessageInput = {
  body: Scalars['String']['input'];
};

export type UpdateUserInput = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  tel?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserSearchSettingsInput = {
  city?: InputMaybe<Array<Scalars['String']['input']>>;
  maxRent?: InputMaybe<Scalars['Int']['input']>;
  minRent?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserSettingsInput = {
  receiveUpdatesOnEmail?: InputMaybe<Scalars['Boolean']['input']>;
  receiveUpdatesOnTelegram?: InputMaybe<Scalars['Boolean']['input']>;
  receiveUpdatesOnWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  invoices: Array<Maybe<Invoice>>;
  isActive: Scalars['Boolean']['output'];
  lookingFor: LookingFor;
  name: Scalars['String']['output'];
  settings: Settings;
  subscription: UserSubscription;
  tel: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type VideoMessage = {
  __typename?: 'VideoMessage';
  id?: Maybe<Scalars['String']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  sha256?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WaContactInput = {
  profile: ProfileInput;
  wa_id: Scalars['String']['input'];
};

export type WaMessage = {
  __typename?: 'WAMessage';
  _id: Scalars['ID']['output'];
  from: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<ImageMessage>;
  text?: Maybe<TextMessage>;
  timestamp: Scalars['String']['output'];
  type: Scalars['String']['output'];
  video?: Maybe<VideoMessage>;
};

export type WhatsAppWebhook = {
  __typename?: 'WhatsAppWebhook';
  _id: Scalars['ID']['output'];
  entry: Array<Entry>;
  object: Scalars['String']['output'];
};

export type WhatsAppWebhookFilters = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  tels?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type WhatsAppWebhookInput = {
  message: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

export type CityCount = {
  __typename?: 'cityCount';
  city?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type FindMetrics = {
  __typename?: 'findMetrics';
  countPerCity?: Maybe<Array<Maybe<CityCount>>>;
  houseSources?: Maybe<Array<Maybe<HouseOrigin>>>;
  houses?: Maybe<Array<Maybe<House>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type House = {
  __typename?: 'house';
  _id: Scalars['ID']['output'];
  bedrooms?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  hostname?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  pathname?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  rooms?: Maybe<Scalars['Float']['output']>;
  search?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  surface?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type HouseMetrics = {
  __typename?: 'houseMetrics';
  averagePrice?: Maybe<Scalars['Float']['output']>;
  averagePricePerSquareMeter?: Maybe<Scalars['Float']['output']>;
  averageRooms?: Maybe<Scalars['Float']['output']>;
  averageSurface?: Maybe<Scalars['Float']['output']>;
  housesCount?: Maybe<Scalars['Int']['output']>;
  housesWithDataCount?: Maybe<Scalars['Int']['output']>;
};

export type HouseMetricsByPrice = {
  __typename?: 'houseMetricsByPrice';
  housesAbove5000?: Maybe<HouseMetrics>;
  housesBetween0and1500?: Maybe<HouseMetrics>;
  housesBetween1500and2000?: Maybe<HouseMetrics>;
  housesBetween2000and2500?: Maybe<HouseMetrics>;
  housesBetween2500and3000?: Maybe<HouseMetrics>;
  housesBetween3000and3500?: Maybe<HouseMetrics>;
  housesBetween3500and4000?: Maybe<HouseMetrics>;
  housesBetween4000and4500?: Maybe<HouseMetrics>;
  housesBetween4500and5000?: Maybe<HouseMetrics>;
  housesCount?: Maybe<Scalars['Int']['output']>;
  housesMetrics?: Maybe<HouseMetrics>;
};

export type HouseOrigin = {
  __typename?: 'houseOrigin';
  count?: Maybe<Scalars['Int']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
};

export type HousesStats = {
  __typename?: 'housesStats';
  averageHousesPerDay?: Maybe<Scalars['Float']['output']>;
  averagePrice?: Maybe<Scalars['Float']['output']>;
  totalHouses?: Maybe<Scalars['Int']['output']>;
  totalUsers?: Maybe<Scalars['Int']['output']>;
};

export enum Packages {
  Medium = 'medium',
  Premium = 'premium',
  Small = 'small'
}

export type UserSubscription = {
  __typename?: 'userSubscription';
  _id: Scalars['ID']['output'];
  activatedAt?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  hasExpiredPayment: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  messages?: Maybe<Messages>;
  package?: Maybe<Packages>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
};
