/** @jsxImportSource @emotion/react */

import { useNavigateAndTrack } from '@/hooks/useNavigateAndTrack';
import { Button, Text } from '@sal-solution/ui-web';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import 'twin.macro';
import tw from 'twin.macro';
import { Section } from '../../../components';
import { useLocale } from '../../../hooks/useLocal';
import { homePageLocale } from '../locale/homePage.locale';
import chooseHouseSVG from './assets/choose-house.svg';
export const SubscribeNow: FC = () => {
  const locale = useLocale(homePageLocale);
  const subscribeSection = locale.subscribeSection;
  const route = useNavigateAndTrack();

  return (
    <Section>
      <div tw="hero-content flex-col w-full pb-24  lg:(flex-row  pb-36)">
        <Image
          src={chooseHouseSVG.src}
          tw="hidden w-full rounded-lg shadow-2xl lg:(max-w-sm block) "
          width={chooseHouseSVG.width}
          height={chooseHouseSVG.height}
          alt="choose house"
        />
        <div tw="ml-4 text-center lg:text-left">
          <h1 tw="text-2xl lg:text-5xl font-bold">{subscribeSection.title}</h1>
          <p tw="py-6">{subscribeSection.description}</p>
          <Button onClick={() => route('/sign-up', 'subscribe-now-sign-up')}>
            {subscribeSection.cta}
          </Button>
        </div>
      </div>
    </Section>
  );
};

const CTASection = tw.div`text-left text-white bg-violet-600 rounded-xl shadow-xl w-full py-4 px-4 overflow-hidden relative before:(absolute right-32 -bottom-20 w-44 h-44 rounded-full bg-white bg-opacity-50) after:(absolute w-60 h-60 -right-12 -bottom-12 rounded-full bg-white bg-opacity-50) lg:(px-16 py-24)`;

export const SubscribeNowCardSection: FC = () => {
  const locale = useLocale(homePageLocale);
  const subscribeSection = locale.subscribeSection;
  return (
    <Section tw="px-4 pb-24 lg:(flex-row pb-36 px-0) ">
      <CTASection tw="">
        <Text as="h1" tw="text-2xl lg:text-5xl font-bold">
          {subscribeSection.title}
        </Text>
        <Text tw="py-6">{subscribeSection.description}</Text>
        <Button
          tw="bg-white text-violet-600 hover:text-white"
          as={Link}
          href="/sign-up"
        >
          {subscribeSection.cta}
        </Button>
      </CTASection>
    </Section>
  );
};
