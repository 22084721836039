/** @jsxImportSource @emotion/react */

import { Button, Form, INPUT_BASE, Input, Text } from '@sal-solution/ui-web';
import React from 'react';
import { toast } from 'react-toastify';
import 'twin.macro';
import tw from 'twin.macro';
import { PageLayout, Section } from '../../components';
import { useLocale } from '../../hooks/useLocal';
import { useContactMutation } from './contact.generated';
import { NextPage } from 'next';
import { localeEN, localeNL } from '../../utils/locale';
import { sendGTMEvent } from '@next/third-parties/google';

export const ContactPage: NextPage = () => {
  const locale = useLocale(ContactLocale);
  const [contact, { loading, error, data }] = useContactMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendGTMEvent({ event: 'contact', value: 'submit-contact-form' });
    const form = new FormData(e.currentTarget);
    const message = form.get('contact-message') as string;
    const name = form.get('name') as string;
    const email = form.get('email') as string;
    const phone = form.get('phone') as string;
    const subject = form.get('subject') as string;
    if (!name) return toast.error('Name cannot be empty');
    if (!email) return toast.error('Email cannot be empty');
    if (!phone) return toast.error('Phone cannot be empty');
    if (!subject) return toast.error('Subject cannot be empty');
    if (!message) return toast.error('Message cannot be empty');
    try {
      await contact({
        variables: {
          input: {
            message,
            name,
            email,
            phone,
            subject,
          },
        },
      });
      toast.success('Message sent successfully');
    } catch (error) {
      toast.error('Message failed to send');
      console.error(error);
    }
  };
  return (
    <PageLayout>
      <Section tw="flex-col lg:(px-0 col-start-5 col-end-9)">
        {error && (
          <div role="alert" tw="alert alert-error w-full text-white mb-4">
            <span>{error.message}</span>
          </div>
        )}
        {data && (
          <div role="alert" tw="alert alert-success w-full text-white mb-4">
            <span>{data.contact}</span>
          </div>
        )}

        <Form
          tw="space-y-4 shadow-2xl px-8 py-4 rounded-xl w-full lg:(grid grid-cols-2 gap-4)"
          onSubmit={onSubmit}
        >
          <Text
            variant="title"
            tw="w-full text-left lg:(col-start-1 col-end-3)"
          >
            {locale.title}
          </Text>
          <Input
            label={locale.formFields.name}
            name="name"
            type="text"
            placeholder={locale.formFields.name}
            containerCSS={[tw`lg:(col-span-2)`]}
          />
          <Input
            label={locale.formFields.email}
            name="email"
            type="email"
            placeholder={locale.formFields.email}
          />
          <Input
            label={locale.formFields.phone}
            name="phone"
            type="text"
            placeholder={locale.formFields.phone}
          />
          <Input
            containerCSS={[tw`lg:(col-start-1 col-end-3)`]}
            label={locale.formFields.subject}
            name="subject"
            type="text"
            placeholder={locale.formFields.subject}
          />

          <textarea
            css={[
              INPUT_BASE,
              tw`border-2 border-gray-300 min-h-[300px] lg:(col-start-1 col-end-3)`,
            ]}
            name="contact-message"
            placeholder={locale.formFields.message}
          />
          <div>
            <Button type="submit" loading={loading}>
              {locale.cta}
            </Button>
          </div>
        </Form>
      </Section>
    </PageLayout>
  );
};

const ContactLocale = {
  [localeEN]: {
    title: 'Any questions or feedback? Feel free to reach out to us!',
    cta: 'Send',
    formFields: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      subject: 'Subject',
      message: 'Message',
    },
  },
  [localeNL]: {
    title: 'Heeft u vragen of feedback? Neem gerust contact met ons op!',
    cta: 'Vertuur',
    formFields: {
      name: 'Naam',
      email: 'Email',
      phone: 'Telefoon',
      subject: 'Onderwerp',
      message: 'Bericht',
    },
  },
};
