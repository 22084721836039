import { sendGTMEvent } from '@next/third-parties/google';
import { useRouter } from 'next/navigation';

export const useNavigateAndTrack = () => {
  const route = useRouter();

  const navigate = (path: string, value: string) => {
    sendGTMEvent({ event: 'navigate', value });
    route.push(path);
  };

  return navigate;
};
