/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { PageLayout, Section } from '../../components';
import { useLoginMutation } from './login.generated';
import { Form } from '@sal-solution/ui-web';
import { useRouter } from 'next/navigation';
import { toast } from 'react-toastify';
import { authLink, client, httpLink, useAppContext } from '../../contexts';
import Link from 'next/link';
import { useLocale } from '../../hooks/useLocal';
import { NextPage } from 'next';
import { localeEN, localeNL } from '../../utils/locale';

export const SignInPage: NextPage = () => {
  const locale = useLocale(locales);
  const { setIsSignedIn } = useAppContext();
  const [login, { error, loading }] = useLoginMutation();
  const router = useRouter();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const email = form.get('email') as string;
    const password = form.get('password') as string;

    console.log('email', email);
    console.log('password', password);

    if (!email || !password)
      return toast.error('Email and password are required');
    try {
      const response = await login({ variables: { email, password } });
      console.log('response', response);
      if (response.data?.login?.token) {
        document.cookie = `token=${response.data.login.token};`;

        client.setLink(authLink.concat(httpLink));
        setIsSignedIn(true);
        router.push('/profile');
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <PageLayout>
      <Section>
        <div tw="hero min-h-screen ">
          <div tw="hero-content flex-col lg:flex-row-reverse">
            <div tw="text-center lg:text-left">
              <h1 tw="text-5xl font-bold">{locale.title}</h1>
              <p tw="py-6">{locale.description}</p>
            </div>
            <div tw="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
              <Form onSubmit={onSubmit} tw="card-body">
                {error && <div tw="alert alert-error">{error.message}</div>}
                <div tw="form-control">
                  <label tw="label">
                    <span tw="label-text">{locale.email}</span>
                  </label>
                  <input
                    name="email"
                    type="email"
                    placeholder="email"
                    tw="input input-bordered"
                    required
                  />
                </div>
                <div tw="form-control">
                  <label tw="label">
                    <span tw="label-text">{locale.password}</span>
                  </label>
                  <input
                    name="password"
                    type="password"
                    placeholder={locale.password}
                    tw="input input-bordered"
                    required
                  />
                  <label tw="label">
                    <Link
                      href="/forgot-password"
                      tw="label-text-alt link link-hover"
                    >
                      {locale.forgotPassword}
                    </Link>
                  </label>
                </div>
                <div tw="form-control mt-6">
                  <button tw="btn btn-primary">
                    {loading && <span tw="loading loading-spinner"></span>}
                    {locale.login}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </PageLayout>
  );
};

// locales for sign in page in nl-nl and en-en
const locales = {
  [localeEN]: {
    title: 'Sign In',
    description: 'Sign in to your account',
    email: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot password?',
    login: 'Login',
  },
  [localeNL]: {
    title: 'Inloggen',
    description: 'Log in op uw account',
    email: 'E-mail',
    password: 'Wachtwoord',
    forgotPassword: 'Wachtwoord vergeten?',
    login: 'Inloggen',
  },
};
