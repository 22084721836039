/** @jsxImportSource @emotion/react */

import { steps } from '@/consts/consts';
import { useCurrentLocale, useLocale } from '@/hooks/useLocal';
import { localeNL } from '@/utils/locale';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { Form, Stepper, Text } from '@sal-solution/ui-web';
import { NextPage } from 'next';
import 'twin.macro';
import tw from 'twin.macro';
import { PageLayout, Reviews, Section } from '../../components';
import { useSignup } from './hooks/useSignup';
import { locales } from './locale/signUpLocale';
import { UserInformation } from './steps/UserInformation';
import { UserPackage } from './steps/UserPackage';
import { UserPreferance } from './steps/UserPreferance';

export const SignUpPage: NextPage = () => {
  const { step, loading, error, setStep, onSubmit } = useSignup();
  const currentLocale = useCurrentLocale();
  const locale = useLocale(locales);

  const stepsLocale = steps[currentLocale || localeNL];

  const stepTitle = () => {
    if (step === 1) {
      return locale['Sign Up (Step 1 of 3)'];
    }
    if (step === 2) {
      return locale['Sign Up (Step 2 of 3)'];
    }
    if (step === 3) {
      return locale['Sign Up (Step 3 of 3)'];
    }
    return locale['Sign Up (Step 3 of 3)'];
  };

  return (
    <PageLayout>
      <Section tw="flex-col space-x-0 space-y-4 px-4 lg:(px-0 col-start-5 col-end-9)">
        <Stepper currentStep={step} steps={stepsLocale} />
        <Form tw="shadow-2xl p-8 rounded space-y-4 w-full " onSubmit={onSubmit}>
          {loading && (
            <div tw="w-full h-full fixed left-0 top-0 bg-black bg-opacity-50 z-10 flex items-center justify-center">
              <Text tw="text-white text-lg">Loading... </Text>
              <ArrowPathIcon tw="w-8 h-8 animate-spin text-blue-300" />
            </div>
          )}
          {error && <Text tw="text-red-500">{error.message}</Text>}
          <Text tw="text-base" as="h1" variant="title">
            {stepTitle()}
          </Text>

          <div css={[step === 1 ? tw`space-y-2` : tw`hidden`]}>
            <UserPreferance onNextStep={setStep} />
          </div>
          {/* <div css={[step === 2 ? tw`space-y-2` : tw`hidden`]}>
            <UserNotification onNextStep={setStep} />
          </div> */}
          <div css={[step === 2 ? tw`space-y-2` : tw`hidden`]}>
            <Text>
              {locale.Credentails}{' '}
              <Text as="span" tw="text-red-500">
                *
              </Text>
            </Text>
            <UserInformation onNextStep={setStep} />
          </div>
          <div css={[step === 3 ? tw`space-y-2` : tw`hidden`]}>
            <UserPackage onNextStep={setStep} />
          </div>
          <Text as="q" tw="italic lg:text-xs text-center">
            {locale['By subscribing you agree to our terms and conditions']}
          </Text>
        </Form>
      </Section>
      <Section tw="px-4 overflow-hidden">
        <Reviews />
      </Section>
    </PageLayout>
  );
};
