import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LookingForQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LookingForQuery = { __typename?: 'Query', findMe?: { __typename?: 'User', lookingFor: { __typename?: 'LookingFor', city?: Array<string> | null, minRent?: number | null, maxRent?: number | null } } | null };


export const LookingForDocument = gql`
    query lookingFor {
  findMe {
    lookingFor {
      city
      minRent
      maxRent
    }
  }
}
    `;

/**
 * __useLookingForQuery__
 *
 * To run a query within a React component, call `useLookingForQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookingForQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookingForQuery({
 *   variables: {
 *   },
 * });
 */
export function useLookingForQuery(baseOptions?: Apollo.QueryHookOptions<LookingForQuery, LookingForQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookingForQuery, LookingForQueryVariables>(LookingForDocument, options);
      }
export function useLookingForLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookingForQuery, LookingForQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookingForQuery, LookingForQueryVariables>(LookingForDocument, options);
        }
export function useLookingForSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LookingForQuery, LookingForQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LookingForQuery, LookingForQueryVariables>(LookingForDocument, options);
        }
export type LookingForQueryHookResult = ReturnType<typeof useLookingForQuery>;
export type LookingForLazyQueryHookResult = ReturnType<typeof useLookingForLazyQuery>;
export type LookingForSuspenseQueryHookResult = ReturnType<typeof useLookingForSuspenseQuery>;
export type LookingForQueryResult = Apollo.QueryResult<LookingForQuery, LookingForQueryVariables>;