import { localeEN, localeNL } from '../utils/locale';

export const steps = {
  [localeNL]: [
    'Zoek voorkeuren',
    // 'Notificatie voorkeuren',
    'Persoonlijke gegevens',
    'Pakket kiezen',
  ],
  [localeEN]: [
    'Search preferances',
    // 'Notification preferences',
    'Personal Information',
    'Choose package',
  ],
};
