import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitHouseMutationVariables = Types.Exact<{
  houseId: Types.Scalars['ID']['input'];
}>;


export type VisitHouseMutation = { __typename?: 'Mutation', visitHouse: string };


export const VisitHouseDocument = gql`
    mutation visitHouse($houseId: ID!) {
  visitHouse(houseId: $houseId)
}
    `;
export type VisitHouseMutationFn = Apollo.MutationFunction<VisitHouseMutation, VisitHouseMutationVariables>;

/**
 * __useVisitHouseMutation__
 *
 * To run a mutation, you first call `useVisitHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitHouseMutation, { data, loading, error }] = useVisitHouseMutation({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useVisitHouseMutation(baseOptions?: Apollo.MutationHookOptions<VisitHouseMutation, VisitHouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitHouseMutation, VisitHouseMutationVariables>(VisitHouseDocument, options);
      }
export type VisitHouseMutationHookResult = ReturnType<typeof useVisitHouseMutation>;
export type VisitHouseMutationResult = Apollo.MutationResult<VisitHouseMutation>;
export type VisitHouseMutationOptions = Apollo.BaseMutationOptions<VisitHouseMutation, VisitHouseMutationVariables>;