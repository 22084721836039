import { localeNL, localeEN } from '@/utils/locale';
import { Packages } from '@sal-solution/types/types/housing/types';

// locales for sign up page for nl-nl and en-us
export const locales = {
  [localeNL]: {
    tryItForFree: 'Probeer het 14 dagen gratis',
    monthlyUnsubscribe: 'Maandelijks opzegbaar',
    perMonth: 'p/m',
    highLight: '14 dagen',
    Back: 'Terug',
    Next: 'Volgende',
    // 'Sign Up (Step 1 of 4)': 'Woning voorkeur (Stap 1 van 4)',
    // 'Sign Up (Step 2 of 4)': 'Notificatie voorkeur (Stap 2 van 4)',
    // 'Sign Up (Step 3 of 4)': 'Uw gegevens (Stap 3 van 4)',
    // 'Sign Up (Step 4 of 4)': 'Kies je pakket (Stap 4 van 4)',
    'Sign Up (Step 1 of 3)': 'Woning voorkeur (Stap 1 van 3)',
    'Sign Up (Step 2 of 3)': 'Uw gegevens (Stap 2 van 3)',
    'Sign Up (Step 3 of 3)': 'Kies je pakket (Stap 3 van 3)',
    title: 'Aanmelden',
    Name: 'Naam',
    FirstName: 'Voornaam',
    LastName: 'Achternaam',
    Credentials: 'Vul je gegevens in',
    Email: 'E-mail',
    Password: 'Wachtwoord',
    'Confirm Password': 'Bevestig wachtwoord',
    Phone: 'Telefoon',
    City: 'Stad',
    Credentails: 'Inloggegevens',
    Payment: 'Abonneer nu',
    'By subscribing you agree to our terms and conditions':
      'Door je te abonneren, gaat je akkoord met onze algemene voorwaarden',
    selectedPackage: 'Geselecteerd pakket',
    packages: {
      title: 'Selecteer een pakket',
      subTitle: 'Wilt u meer informatie over onze pakketten?',
      select: 'Kies',
      favorite: 'Meest gekozen',
      chargeRate: 'p/m',
      [Packages.Small]: {
        name: 'Email pakket',
        service: [`✉️ Onbeperkt emails`],
        price: '€9,99',
        package: Packages.Small,
      },
      [Packages.Medium]: {
        name: 'WhatsApp startpakket',
        service: [`✉️ Onbeperkt emails`, `💬 Tot 5 WhatsApp berichten per dag`],
        price: '€14,99',
        package: Packages.Medium,
      },
      [Packages.Premium]: {
        name: 'Onbeperkt pakket',
        service: [
          `✉️ Onbeperkt emails`,
          `💬 Onbeperkt WhatsApp berichten per dag`,
        ],
        price: '€19,99',
        package: Packages.Premium,
      },
    },
  },
  [localeEN]: {
    perMonth: 'per month',
    tryItForFree: 'Try it for free for 14 days',
    monthlyUnsubscribe: 'Monthly unsubscribe',
    highLight: '14 days',
    Back: 'Back',
    Next: 'Next',
    // 'Sign Up (Step 1 of 4)':
    //   'What kind of housing are you looking for? (Step 1 of 4)',
    // 'Sign Up (Step 2 of 4)': 'Your notification preferences (Step 2 of 4)',
    // 'Sign Up (Step 3 of 4)': 'Your information (Step 3 of 4)',
    // 'Sign Up (Step 4 of 4)': 'Select your package (Step 3 of 4)',
    'Sign Up (Step 1 of 3)':
      'What kind of housing are you looking for? (Step 1 of 3)',
    'Sign Up (Step 2 of 3)': 'Your information (Step 2 of 3)',
    'Sign Up (Step 3 of 3)': 'Select your package (Step 3 of 3)',
    Credentials: 'Fill in your details',
    title: 'Sign Up',
    Name: 'Name',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Email: 'Email',
    Password: 'Password',
    'Confirm Password': 'Confirm Password',
    Phone: 'Phone',
    City: 'City',
    Credentails: 'Credentails',
    Payment: 'Subscribe Now',
    'By subscribing you agree to our terms and conditions':
      'By subscribing you agree to our terms and conditions',
    selectedPackage: 'Selected Package',
    packages: {
      favorite: 'Most chosen',
      title: 'Select a package',
      subTitle: 'Want more information about our packages?',
      select: 'Select',
      chargeRate: 'p/m',
      [Packages.Small]: {
        name: 'Email package',
        service: [`✉️ Unlimited emails`],
        price: '€9,99',
        package: Packages.Small,
      },
      [Packages.Medium]: {
        name: 'WhatsApp start package',
        service: [`💬 Up to 5 new homes via WhatsApp`],
        price: '€14,99',
        package: Packages.Medium,
      },
      [Packages.Premium]: {
        name: 'Unlimited package',
        service: [`💬 Unlimited new homes via WhatsApp`],
        price: '€19,99',
        package: Packages.Premium,
      },
    },
  },
};
