import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserSearchSettingsMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UpdateUserSearchSettingsInput>;
}>;


export type UpdateUserSearchSettingsMutation = { __typename?: 'Mutation', updateUserSearchSettings?: { __typename?: 'User', lookingFor: { __typename?: 'LookingFor', city?: Array<string> | null, maxRent?: number | null, minRent?: number | null } } | null };


export const UpdateUserSearchSettingsDocument = gql`
    mutation updateUserSearchSettings($input: UpdateUserSearchSettingsInput) {
  updateUserSearchSettings(input: $input) {
    lookingFor {
      city
      maxRent
      minRent
    }
  }
}
    `;
export type UpdateUserSearchSettingsMutationFn = Apollo.MutationFunction<UpdateUserSearchSettingsMutation, UpdateUserSearchSettingsMutationVariables>;

/**
 * __useUpdateUserSearchSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSearchSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSearchSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSearchSettingsMutation, { data, loading, error }] = useUpdateUserSearchSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSearchSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSearchSettingsMutation, UpdateUserSearchSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSearchSettingsMutation, UpdateUserSearchSettingsMutationVariables>(UpdateUserSearchSettingsDocument, options);
      }
export type UpdateUserSearchSettingsMutationHookResult = ReturnType<typeof useUpdateUserSearchSettingsMutation>;
export type UpdateUserSearchSettingsMutationResult = Apollo.MutationResult<UpdateUserSearchSettingsMutation>;
export type UpdateUserSearchSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserSearchSettingsMutation, UpdateUserSearchSettingsMutationVariables>;