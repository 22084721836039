/** @jsxImportSource @emotion/react */

import {
  ArrowRightIcon,
  CreditCardIcon,
  EnvelopeIcon,
  HomeIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Button, Form, Input, Stepper, Text } from '@sal-solution/ui-web';
import { toast } from 'react-toastify';
import tw from 'twin.macro';
import { PageLayout, Section } from '../../components';
import { steps } from '../../consts/consts';
import { useSubscribe } from '../../contexts/Subscribe.context';
import { useCurrentLocale, useLocale } from '../../hooks/useLocal';
import { useCreateCheckoutUrlMutation } from './createCheckoutUrl.generated';
import { useCreateUserMutation } from './createUser.generated';
import { NextPage } from 'next';
import { localeEN, localeNL } from '../../utils/locale';

const Row = tw.div`flex flex-col lg:flex-row items-center gap-4`;

export const SubscribePage: NextPage = () => {
  const locale = useLocale(subscripePageLocales);
  const currentLocale = useCurrentLocale();
  const stepsLocale = steps[currentLocale || localeNL];
  const { newSubscriber } = useSubscribe();
  const [createCheckoutUrl, createCheckoutUrlResponse] =
    useCreateCheckoutUrlMutation();
  const [createUser, createUserResponse] = useCreateUserMutation();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!newSubscriber) return;
    const createUserResponse = await createUser({
      variables: {
        input: {
          email: newSubscriber.email,
          name: newSubscriber.name,
          password: newSubscriber.password,
          tel: newSubscriber.phone,
          city: newSubscriber.city,
        },
      },
    });

    if (createUserResponse.errors)
      return toast.error(createUserResponse.errors[0].message);
    else if (createUserResponse.data?.createUser?._id)
      toast.success('User created successfully');

    const checkoutUrl = await createCheckoutUrl({
      variables: {
        input: {
          userId: createUserResponse.data?.createUser?._id,
        },
      },
    });
    if (checkoutUrl.errors) return toast.error(checkoutUrl.errors[0].message);

    window.open(checkoutUrl.data?.createCheckoutUrl, '_blank');
  };

  const loading =
    createCheckoutUrlResponse.loading || createUserResponse.loading;
  const error = createCheckoutUrlResponse.error || createUserResponse.error;
  return (
    <PageLayout>
      <Section tw="flex-col space-x-0 space-y-4  px-4 lg:px-0">
        <Stepper currentStep={3} steps={stepsLocale} />
        <Form tw="shadow-2xl p-8 rounded space-y-4 w-full" onSubmit={onSubmit}>
          {error && <Text tw="text-red-500">{error.message}</Text>}
          <Text as="h1" variant="title">
            {locale.title}
          </Text>
          <Text as="h2" variant="subTitle">
            {locale.confirm}
          </Text>
          <Row>
            <Input
              iconLeft={<UserIcon />}
              placeholder={locale.name}
              name="name"
              value={newSubscriber?.name}
            />
            <Input
              iconLeft={<EnvelopeIcon />}
              placeholder={locale.email}
              name="email"
              value={newSubscriber?.email}
            />
          </Row>
          <Row>
            <Input
              iconLeft={<PhoneIcon />}
              placeholder={locale.phone}
              name="phone"
              value={newSubscriber?.phone}
            />
            <Input
              iconLeft={<HomeIcon />}
              placeholder={locale.city}
              name="city"
              value={newSubscriber?.city}
            />
          </Row>
          <Button tw="flex items-center justify-center gap-2" type="submit">
            <CreditCardIcon tw="w-4" /> {locale.createAccount}
            <ArrowRightIcon tw="w-4" />
          </Button>
          <Text as="q" tw="italic lg:text-xs text-center">
            {locale.terms}
          </Text>
        </Form>
      </Section>
    </PageLayout>
  );
};

// nl and en locales for subscribe confirmation page including cta's

const subscripePageLocales = {
  [localeEN]: {
    confirm: 'Confirm your details',
    title: 'Start your subscription now for only €19,99 per month',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    city: 'City',
    createAccount: 'Create Account & Pay',
    terms: 'By subscribing you agree to our terms and conditions',
  },
  [localeNL]: {
    title: 'Start uw abonnement nu voor slechts €19,99 per maand',
    confirm: 'Bevestig uw gegevens',
    name: 'Naam',
    email: 'Email',
    phone: 'Telefoon',
    city: 'Stad',
    createAccount: 'Account aanmaken & betalen',
    terms: 'Door te abonneren gaat u akkoord met onze algemene voorwaarden',
  },
};
