/** @jsxImportSource @emotion/react */

import { Button, Text } from '@sal-solution/ui-web';
import { NextPage } from 'next';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import 'twin.macro';
import { PageLayout, Section } from '../../components';
import { useLocale } from '../../hooks/useLocal';
import { localeEN, localeNL } from '../../utils/locale';
import { useEffect } from 'react';
import * as fbq from '@/lib/fpixel';

export const SubscriptionConfirmationPage: NextPage = () => {
  const locale = useLocale(locales);
  const queryParams = useSearchParams();
  const isSuccess = queryParams.get('success');
  const userId = queryParams.get('uid');

  useEffect(() => {
    if (isSuccess === 'true') {
      fbq.event('Subscribe', {
        currency: 'EUR',
        value: 10,
        external_id: userId,
      });
    }
  }, [isSuccess]);

  if (isSuccess === 'false') {
    return (
      <PageLayout>
        <Section tw="flex-col my-4 text-center">
          <Text as="h1" variant="title">
            {locale.failed.title}
          </Text>
          <Text>{locale.failed.description}</Text>
          <div tw="space-x-4">
            <Button as={Link} href="/contact" tw="mt-8">
              {locale.failed.contact}
            </Button>
            <Button as={Link} href="/sign-up" tw="mt-8">
              {locale.failed.tryAgain}
            </Button>
          </div>
        </Section>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <Section tw="flex-col text-center">
        <Text as="h1" variant="title">
          {locale.title}
        </Text>
        <Text>{locale.description}</Text>
        <Text tw="mt-4">{locale.email}</Text>
        <Button tw="mt-8" as={Link} href="/sign-in">
          {locale.login}
        </Button>
      </Section>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            gtag('event', 'conversion', {
            'send_to': 'AW-10833959434/TdDiCI6dmrAZEIq0hK4o'
            });
          `,
        }}
      ></script>
    </PageLayout>
  );
};

const locales = {
  [localeEN]: {
    title: 'You have successfully subscribed! 🎉',
    description:
      'You will start receiving an email and WhatsApp messages with the latest news and updates from us.',
    email: 'You can sign in to your account to manage your subscription.',
    login: 'Sign in',
    failed: {
      title: 'Subscription failed',
      description:
        'Please try again later or reach out to us on the contact page.',
      contact: 'Contact us',
      tryAgain: 'Try again',
    },
  },
  [localeNL]: {
    title: 'U heeft zich succesvol aangemeld! 🎉',
    description:
      'U ontvangt e-mails en/of WhatsApp-berichten met het laatste nieuws en updates van ons.',
    email: 'U kunt inloggen op uw account om uw abonnement te beheren.',
    login: 'Inloggen',
    failed: {
      title: 'Abonnement mislukt',
      description:
        'Probeer het later opnieuw of neem contact met ons op via de contactpagina.',
      contact: 'Neem contact op',
      tryAgain: 'Probeer opnieuw',
    },
  },
};
