/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { Reviews } from '@/components';
import { Section } from '@sal-solution/ui-web';
import { FC } from 'react';

export const OurReviews: FC = () => {
  return (
    <Section tw="flex overflow-hidden lg:(col-start-1 col-end-13)">
      <Reviews />
    </Section>
  );
};
