/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { useLocale } from '@/hooks/useLocal';
import * as Icon from '@heroicons/react/24/outline';
import { Button, Text } from '@sal-solution/ui-web';
import { toCurrency } from '@sal-solution/utils';
import { FC } from 'react';
import { useFindMyInvoicesQuery } from '../../Profile/findMyInvoices.generated';
import { subscriptionLocales } from '../Subscription';

const numberToDate = (number?: number | string | null | undefined) => {
  if (!number) return '';
  if (typeof number === 'string') number = parseInt(number);
  const date = new Date(number);
  return date.toLocaleDateString();
};

export const Invoices: FC = () => {
  const locale = useLocale(subscriptionLocales);
  const { data, error, refetch } = useFindMyInvoicesQuery();

  return (
    <>
      {error && (
        <div role="alert" tw="alert alert-error w-full text-white mb-4">
          <button onClick={() => refetch()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              tw="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <span>{error.message}</span>
        </div>
      )}
      <Text as="h2" variant="subTitle">
        {locale.invoices}
      </Text>
      <div className="overflow-x-scroll">
        <table tw="table">
          {/* head */}
          <thead>
            <tr>
              <th></th>
              <th>{locale.amount}</th>
              <th>{locale.paid}</th>
              <th>{locale.chargedBack}</th>
              <th>{locale.refunded}</th>
              <th>{locale.createdAt}</th>
              <th>{locale.actions}</th>
            </tr>
          </thead>
          <tbody>
            {data?.findMyInvoices.map((invoice, index) => (
              <tr key={index}>
                <th>{index + 1}</th>
                <td>
                  {invoice?.amount ? toCurrency(invoice.amount / 100) : 'NaN'}
                </td>
                <td>
                  {invoice?.isPaid ? (
                    <Icon.CheckCircleIcon tw="w-6 text-green-400" />
                  ) : (
                    <Icon.XCircleIcon tw="w-6 text-red-400" />
                  )}
                </td>
                <td>
                  {invoice?.isChargedBack ? (
                    <Icon.CheckCircleIcon tw="w-6 text-green-400" />
                  ) : (
                    <Icon.XCircleIcon tw="w-6 text-red-400" />
                  )}
                </td>
                <td>
                  {invoice?.isRefunded ? (
                    <Icon.CheckCircleIcon tw="w-6 text-green-400" />
                  ) : (
                    <Icon.XCircleIcon tw="w-6 text-red-400" />
                  )}
                </td>
                <td>
                  {numberToDate(parseInt(invoice?.createdAt || '0') * 1000)}
                </td>
                <td>
                  {invoice?.isChargedBack ? (
                    <Button>{locale.pay}</Button>
                  ) : (
                    false
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
