/** @jsxImportSource @emotion/react */

import { FC } from 'react';
import 'twin.macro';
import { PageLayout, Section } from '../../components';
import { useLocale } from '../../hooks/useLocal';
import PrivacyEng from './PrivacyEng.mdx';
import PrivacyNL from './PrivacyNL.mdx';
import { NextPage } from 'next';
import { localeEN, localeNL } from '../../utils/locale';

export const PrivacyPage: NextPage = () => {
  const Locale = useLocale({
    [localeNL]: PrivacyNL,
    [localeEN]: PrivacyEng,
  });

  return (
    <PageLayout>
      <Section tw="px-4 lg:px-0">
        <div tw="prose lg:prose-xl">
          <Locale />
        </div>
      </Section>
    </PageLayout>
  );
};
