/** @jsxImportSource @emotion/react */

import { FC } from 'react';
import { HousesQuery, Section, useHousesQuery } from '../../../components';
import tw from 'twin.macro';
import { Button, HeroSection, Text } from '@sal-solution/ui-web';
import { toCurrency } from '@sal-solution/utils';
import Image from 'next/image';
import { Loading } from '../../../app/loading';
import whatsAppIcon from './assets/whatsapp-icon.png';
import gmailIcon from './assets/gmail-icon.png';
import { homePageLocale } from '../locale/homePage.locale';
import { useLocale } from '../../../hooks/useLocal';
import Link from 'next/link';

type House = HousesQuery['latestHouses'][0];

export const Updates: FC = () => {
  const { data, loading, error } = useHousesQuery();
  const latestHouses = data?.latestHouses?.slice(0, 1);
  return (
    <HeroSection
      topVariant="Waves"
      bottomVariant="Waves"
      topFill="rgb(233 213 255)"
      fill="#fff"
      topContentsStyle={[tw`bg-white`]}
      contentsStyle={[tw`bg-purple-200`]}
    >
      <div tw="px-4 lg:flex w-full justify-between items-center space-y-8 lg:space-y-0 lg:gap-8 py-12 lg:(py-16) 2xl:(py-24)">
        {error && <Text tw="text-center w-full">Error: {error.message}</Text>}

        <div tw="w-full">
          {latestHouses?.map((house, i) => (
            <EmailBubble house={house} key={i} />
          ))}
        </div>
        <div tw="w-full">
          {loading && <Loading />}
          {latestHouses?.map((house, i) => (
            <EmailBubble house={house} key={i} isChat />
          ))}
        </div>
      </div>
    </HeroSection>
  );
};

const SalesCard: FC = () => {
  const locale = useLocale(homePageLocale);
  return (
    <div tw="card w-96 bg-slate-800 shadow-xl text-white">
      <div tw="card-body">
        <Text as="h2" variant="subTitle">
          {locale.salesCard.title}
        </Text>
        <Text>{locale.salesCard.description}</Text>
        <div tw="card-actions justify-end">
          <Button as={Link} href="/sign-up">
            {locale.salesCard.cta}
          </Button>
        </div>
      </div>
    </div>
  );
};

const ChatBubble: FC<{ house: House }> = ({ house }) => {
  const locale = useLocale(homePageLocale);

  return (
    <div
      tw="grid grid-cols-[auto 1fr] gap-x-3 py-1 w-full"
      className="chat chat-start"
    >
      <div tw="chat-image avatar">
        <div tw="w-10 rounded-full">
          <Image
            tw="object-contain"
            alt="Renting agent"
            src={whatsAppIcon.src}
            width={60}
            height={60}
          />
        </div>
      </div>
      <div className="chat-header">
        Rent watcher
        <time tw="text-xs opacity-50 ml-2">
          {locale.appartmentCard.justNow}
        </time>
      </div>
      <div tw="chat-bubble w-full">
        <Text tw="font-bold mb-4">{locale.appartmentCard.title}</Text>
        <Text>{locale.appartmentCard.description}</Text>
        <Text>{house.title}</Text>
        {house.price && (
          <Text>
            {toCurrency(house.price)} - {house.rooms}{' '}
            {locale.appartmentCard.rooms} - {house.surface}m²
          </Text>
        )}
        <Button variant="link" tw="text-white">
          {locale.appartmentCard.cta}
        </Button>
      </div>
      <div tw="chat-footer opacity-50">{locale.appartmentCard.delivered}</div>
    </div>
  );
};

const EmailBubble: FC<{ house: House; isChat?: boolean }> = ({
  house,
  isChat,
}) => {
  const locale = useLocale(homePageLocale);

  return (
    <div tw="p-4 bg-slate-800 bg-opacity-50 backdrop-blur-md w-full rounded-md flex items-center text-white space-x-4">
      <div tw="w-10">
        <Image
          css={[
            isChat
              ? tw`rounded-full object-cover`
              : tw`rounded-md bg-white px-2 py-1`,
          ]}
          src={isChat ? whatsAppIcon.src : gmailIcon.src}
          alt="Renting agent"
          width={40}
          height={40}
        />
      </div>
      <div tw="w-full">
        <div tw="flex justify-between w-full">
          <Text tw="font-bold">
            {isChat ? '🏠 Rent watcher' : locale.appartmentCard.title}
          </Text>
          <Text>{locale.appartmentCard.justNow}</Text>
        </div>
        <Text tw="font-bold">{locale.appartmentCard.newHouseFound}</Text>
        <Text>{house.title}</Text>
        {house.price && (
          <Text>
            {toCurrency(house.price)}
            {/* - {house.rooms}{' '} */}
            {/* {locale.appartmentCard.rooms} - {house.surface}m² */}
          </Text>
        )}
      </div>
    </div>
  );
};
