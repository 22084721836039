/** @jsxImportSource @emotion/react */

import { FC } from 'react';
import 'twin.macro';
import { Section } from '@/components';
import { localeEN, localeNL } from '@/utils/locale';
import { useLocale } from '@/hooks/useLocal';
import { Button, Card, Text } from '@sal-solution/ui-web';
import tw from 'twin.macro';
import { useSubscribe } from '@/contexts/Subscribe.context';
import { useRouter } from 'next/navigation';
import { Packages } from '@sal-solution/types/types/housing/types';
import { packagesLocale as packagesLocaleCommon } from '@/locales/packages';
import { sendGTMEvent } from '@next/third-parties/google';

export const UserPackages: FC = () => {
  const locale = useLocale(packagesLocale);
  const localePackage = useLocale(packagesLocaleCommon);
  const { setUserPackage } = useSubscribe();
  const route = useRouter();

  const logEvent = (selectedPackage: string) => {
    sendGTMEvent({
      event: 'buttonClicked',
      value: `${selectedPackage}-sign-up`,
    });
  };

  const onSelectedPackage = (pkg: Packages) => {
    setUserPackage(pkg);
    logEvent(pkg);
    route.push('/sign-up');
  };

  return (
    <Section tw="flex-col space-y-4 px-4 lg:(px-0 space-y-0 items-start gap-x-4 grid grid-cols-3) py-12 lg:(py-16) 2xl:(py-24)">
      {locale.packages.map((pkg, index) => (
        <Card
          key={index}
          tw="flex flex-col border relative h-full"
          css={pkg.package === Packages.Medium ? [tw`border-violet-500`] : []}
        >
          {pkg.package === Packages.Medium && (
            <div tw="badge badge-primary absolute -top-2 right-4">
              {locale.favourite}
            </div>
          )}
          <Text as="h2" variant="subTitle" tw="text-lg lg:text-2xl">
            {pkg.name}
          </Text>
          <Text tw="italic text-sm" variant="sub">
            {localePackage.monthlyCancel}
          </Text>
          <Text tw="mt-8 mb-4">
            <span tw="font-semibold text-3xl">{pkg.price}</span>{' '}
            <span tw="opacity-80">{locale.chargeRate}</span>
          </Text>
          <Text tw="mb-4">{pkg.description}</Text>
          <ul tw="space-y-1 mb-4">
            {pkg.features.map((feature, index) => (
              <Text as="li" key={index}>
                {feature}
              </Text>
            ))}
          </ul>
          <Button
            tw="mt-auto px-0 text-left"
            variant="primary"
            onClick={() => onSelectedPackage(pkg.package)}
          >
            {locale.CTA}
          </Button>
        </Card>
      ))}
    </Section>
  );
};

const packagesLocale = {
  [localeNL]: {
    title: 'Pakketten',
    chargeRate: 'per maand',
    favourite: 'Meest gekozen',
    CTA: 'Probeer nu!',
    packages: [
      {
        name: 'Email pakket',
        price: '€9,99',
        package: Packages.Small,
        description:
          'Blijf op de hoogte van de nieuwste woningen, via email berichten!',
        features: [
          '✉️  Onbeperkt emails',
          '💬  Geen WhatsApp berichten',
          `🏠  Keuze uit al onze steden`,
        ],
      },
      {
        name: 'WhatsApp startpakket',
        price: '€14,99',
        package: Packages.Medium,
        description:
          'Ontvang tot 5 WhatsApp berichten per dag en bijf ook via email op de hoogte!',
        features: [
          '✉️  Onbeperkt emails',
          '💬  Maximaal 5 WhatsApp berichten per dag',
          `🏠  Keuze uit al onze steden`,
        ],
      },
      {
        name: 'Onbeperkt pakket',
        price: '€19,99',
        package: Packages.Premium,
        description:
          'Mis geen enkel huis meer! Ontvang onbeperkt WhatsApp en email berichten!',
        features: [
          '✉️  Onbeperkt emails',
          '💬  Onbeperkt aantal WhatsApp berichten per dag',
          `🏠  Keuze uit al onze steden`,
        ],
      },
    ],
  },
  [localeEN]: {
    title: 'Packages',
    chargeRate: 'per month',
    CTA: 'Try now!',
    favourite: 'Most popular',
    packages: [
      {
        name: 'Email package',
        price: '€9,99',
        package: Packages.Small,
        description:
          'Stay up to date with the latest houses, via email messages!',
        features: [
          '✉️  Unlimited emails',
          '💬  No WhatsApp notifications',
          `🏠  Choose from all our cities`,
        ],
      },

      {
        name: 'WhatsApp starter package',
        price: '€14,99',
        package: Packages.Medium,
        description:
          'Receive up to 5 WhatsApp messages per day and stay informed via email!',
        features: [
          '✉️  Unlimited emails',
          '💬  Maximum 5 of WhatsApp notifications per day',
          `🏠  Choose from all our cities`,
        ],
      },
      {
        name: 'Unlimited package',
        price: '€19,99',
        package: Packages.Premium,
        description:
          'Never miss a house again! Receive unlimited WhatsApp and email messages!',
        features: [
          '✉️  Unlimited emails',
          '💬  Unlimited WhatsApp notifications per day',
          `🏠  Choose from all our cities`,
        ],
      },
    ],
  },
} as const;
