/** @jsxImportSource @emotion/react */

'use client';
import { Text } from '@sal-solution/ui-web';
import { PageLayout, Section } from '../../components';

export const NotFoundPage = () => {
  return (
    <PageLayout headerContent={<title>404: Page not found!</title>}>
      <Section>
        <Text>404 - Page Not Found</Text>
      </Section>
    </PageLayout>
  );
};
