import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllHousesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AllHousesQuery = { __typename?: 'Query', houses: Array<{ __typename?: 'house', _id: string, city?: string | null, bedrooms?: number | null, price?: number | null, rooms?: number | null, surface?: number | null, title?: string | null, url?: string | null, image?: string | null }> };


export const AllHousesDocument = gql`
    query AllHouses {
  houses {
    _id
    city
    bedrooms
    price
    rooms
    surface
    title
    url
    image
  }
}
    `;

/**
 * __useAllHousesQuery__
 *
 * To run a query within a React component, call `useAllHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllHousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllHousesQuery(baseOptions?: Apollo.QueryHookOptions<AllHousesQuery, AllHousesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllHousesQuery, AllHousesQueryVariables>(AllHousesDocument, options);
      }
export function useAllHousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllHousesQuery, AllHousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllHousesQuery, AllHousesQueryVariables>(AllHousesDocument, options);
        }
export function useAllHousesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllHousesQuery, AllHousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllHousesQuery, AllHousesQueryVariables>(AllHousesDocument, options);
        }
export type AllHousesQueryHookResult = ReturnType<typeof useAllHousesQuery>;
export type AllHousesLazyQueryHookResult = ReturnType<typeof useAllHousesLazyQuery>;
export type AllHousesSuspenseQueryHookResult = ReturnType<typeof useAllHousesSuspenseQuery>;
export type AllHousesQueryResult = Apollo.QueryResult<AllHousesQuery, AllHousesQueryVariables>;