/** @jsxImportSource @emotion/react */

import { Button, Form, Input } from '@sal-solution/ui-web';
import { FC } from 'react';
import { toast } from 'react-toastify';
import 'twin.macro';
import { PageLayout, Section } from '../../components';
import { useLocale } from '../../hooks/useLocal';
import { useResetPasswordMutation } from './resetPassword.generated';
import { NextPage } from 'next';
import { localeEN, localeNL } from '../../utils/locale';

export const ResetPasswordPage: NextPage = () => {
  const locale = useLocale(locales);
  const [request, { loading, error, data }] = useResetPasswordMutation();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const password = form.get('password') as string;
    console.log('password', password);
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) return toast.error('Invalid token');
    if (!password) return toast.error('Password is required');
    try {
      const response = await request({ variables: { password, token } });
      console.log('response', response);
      toast.success('Password reset email sent successfully');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <PageLayout>
      <Section tw="flex-col">
        {error && (
          <div role="alert" tw="alert alert-error w-full text-white mb-4">
            <span>{error.message}</span>
          </div>
        )}
        {data && (
          <div role="alert" tw="alert alert-success w-full text-white mb-4">
            <span>{data.resetPassword}</span>
          </div>
        )}

        <Form
          tw="space-y-4 shadow-2xl px-8 py-4 rounded-xl w-full"
          onSubmit={onSubmit}
        >
          <Input
            label={locale.password}
            name="password"
            type="password"
            placeholder={locale.password}
          />
          <Input
            label={locale.confirmPassword}
            name="confirmPassword"
            type="password"
            placeholder={locale.confirmPassword}
          />
          <Button type="submit" loading={loading}>
            {locale.resetPassword}
          </Button>
        </Form>
      </Section>
    </PageLayout>
  );
};

// nl and en locales for resetpassword page

const locales = {
  [localeEN]: {
    title: 'Reset Password',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    resetPassword: 'Reset Password',
  },
  [localeNL]: {
    title: 'Wachtwoord opnieuw instellen',
    password: 'Wachtwoord',
    confirmPassword: 'Bevestig wachtwoord',
    resetPassword: 'Wachtwoord opnieuw instellen',
  },
};
