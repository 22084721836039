/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { Navigation, PageLayout } from '@/components';
import { Backdrop, SectionDivider } from '@sal-solution/ui-web';
import { NextPage } from 'next';
import { useLocale } from '../../hooks/useLocal';
import { homePageLocale } from './locale/homePage.locale';
import { Hero } from './sections/Hero';
import { HowItWorks } from './sections/HowItWorks';
import { LatestHouses } from './sections/LatestHouses';
import { SubscribeNowCardSection } from './sections/SubscribeNow';
import { Updates } from './sections/Updates';
import { UserPackages } from './sections/UserPackages';
import { Deal } from './sections/Deal';
import { OurReviews } from './sections/OurReviews';

export const HomePage: NextPage = ({ ...rest }) => {
  const locale = useLocale(homePageLocale);

  return (
    <PageLayout
      {...rest}
      headerContent={<title>{locale.pageTitle}</title>}
      navContent={<Navigation />}
    >
      <Hero />
      <Deal id="deal" />
      <SectionDivider title={locale.houses} />
      <LatestHouses />
      <HowItWorks />
      <SectionDivider title={locale.packages} id="packages" />
      <UserPackages />
      <SectionDivider title={locale.reviews} />
      <OurReviews />
      <Updates />
      <SubscribeNowCardSection />
    </PageLayout>
  );
};
