/** @jsxImportSource @emotion/react */

import 'twin.macro';
import React, { FC } from 'react';
import {
  Button,
  Text,
  highLightColors,
  hightLightText,
} from '@sal-solution/ui-web';
import { SelectPackage } from '@/components/SelectPackage/SelectPackage';
import { ArrowLeftIcon, CreditCardIcon } from '@heroicons/react/20/solid';
import { steps } from '../hooks/useSignup';
import { useLocale } from '@/hooks/useLocal';
import { locales } from '../locale/signUpLocale';
import { packagesLocale } from '@/locales/packages';

type UserPackageProps = {
  onNextStep: (e: steps) => void;
};

export const UserPackage: FC<UserPackageProps> = ({ onNextStep }) => {
  const locale = useLocale(locales);
  const highLightedText = hightLightText(
    locale.tryItForFree,
    locale.highLight,
    highLightColors[0]
  );
  return (
    <>
      <Text tw="text-base 2xl:text-xl">{highLightedText}</Text>
      <SelectPackage />
      <div tw="flex gap-4">
        <Button
          tw="flex items-center justify-center gap-2"
          type="button"
          onClick={() => onNextStep(2)}
        >
          <ArrowLeftIcon tw="w-4" />
          {locale.Back}
        </Button>
        <Button tw="flex items-center justify-center gap-2" type="submit">
          {locale.Payment}
          <CreditCardIcon tw="w-4" />
        </Button>
      </div>
    </>
  );
};
