/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { useAllHousesQuery } from './allHouses.generated';
import { HouseCard } from '../../../../components/Houses/HouseCard';
import { Button, Text } from '@sal-solution/ui-web';
import { useLocale } from '../../../../hooks/useLocal';
import { useLookingForQuery } from './lookingFor.generated';
import { useVisitHouseMutation } from './visitHouse.generated';
import { House } from '@sal-solution/types/types/housing/types';
import { localeEN, localeNL } from '../../../../utils/locale';

export const Houses = () => {
  const locale = useLocale(housesLocale);
  const [addId] = useVisitHouseMutation();
  const lookingFor = useLookingForQuery();
  const allHouses = useAllHousesQuery({
    fetchPolicy: 'network-only',
  });
  const loading = allHouses.loading || lookingFor.loading;
  const error = allHouses.error || lookingFor.error;

  const refetch = async () => {
    await allHouses.refetch();
    await lookingFor.refetch();
  };

  const visitHouse = async (house: House) => {
    console.log('houseId', house?._id);
    if (!house?._id) return;
    if (!house?.url) return;

    const response = await addId({ variables: { houseId: house?._id } });
    if (response.data?.visitHouse) window.open(house.url, '_blank');
  };

  return (
    <div>
      {error && (
        <div role="alert" tw="alert alert-error w-full text-white mb-4">
          <button onClick={() => refetch()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              tw="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <span>{error.message}</span>
        </div>
      )}
      <div tw="grid lg:(grid-cols-3 gap-4)">
        <div tw="flex justify-between items-center lg:(col-start-1 col-end-4)">
          <div>
            <Text tw="text-lg font-bold">
              {allHouses.data?.houses.length} {locale.title}
            </Text>
            <div tw="text-sm text-gray-500">
              {lookingFor.data?.findMe?.lookingFor?.city?.join(', ')}
              {lookingFor.data?.findMe?.lookingFor?.minRent &&
                (lookingFor.data?.findMe?.lookingFor?.maxRent
                  ? ` | €${lookingFor.data?.findMe?.lookingFor?.minRent} - €${lookingFor.data?.findMe?.lookingFor?.maxRent}`
                  : ` | €${lookingFor.data?.findMe?.lookingFor?.minRent}`)}
            </div>
          </div>
          <Button onClick={() => refetch()}>
            {loading && (
              <span tw="loading loading-spinner loading-xs mr-4"></span>
            )}
            {locale.cta}
          </Button>
        </div>
        {allHouses.data?.houses.map((house, index) => (
          <button key={index} onClick={() => visitHouse(house)}>
            <HouseCard house={house} />
          </button>
        ))}
      </div>
    </div>
  );
};

const housesLocale = {
  [localeEN]: {
    title: 'Houses found in the last 24 hours',
    cta: 'Refresh',
  },
  [localeNL]: {
    title: 'Gevonden huizen in de afgelopen 24 uur',
    cta: 'Verversen',
  },
};
