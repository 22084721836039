/** @jsxImportSource @emotion/react */

import { useSubscribe } from '@/contexts/Subscribe.context';
import { useLocale } from '@/hooks/useLocal';
import { locales } from '@/modules/SignUpPage/locale/signUpLocale';
import { Packages } from '@sal-solution/types/types/housing/types';
import { Button, Card, Text } from '@sal-solution/ui-web';
import Link from 'next/link';
import { FC } from 'react';
import 'twin.macro';
import tw from 'twin.macro';

export const SelectPackage: FC = () => {
  const locale = useLocale(locales);
  const { userPackage, setUserPackage } = useSubscribe();
  if (userPackage) {
    const selectedPackage = locale.packages[userPackage];
    return (
      <Button
        variant="link"
        type="button"
        onClick={() => setUserPackage(undefined)}
      >
        <span tw="font-semibold">
          {selectedPackage.name} - {selectedPackage.price} -{' '}
          {locale.packages.chargeRate}
        </span>
      </Button>
    );
  }

  const packages = [Packages.Small, Packages.Medium, Packages.Premium] as const;
  const onSelectedPackage = (pkg?: Packages) => {
    setUserPackage(pkg);
  };
  return (
    <>
      <Text as="h2" variant="subTitle">
        {locale.packages.title}{' '}
        <span tw="text-sm">({locale.monthlyUnsubscribe})</span>
      </Text>
      <ul tw="space-y-4">
        {packages.map((pkg) => {
          const selectedPackage = locale.packages[pkg];
          return (
            <li key={pkg}>
              <Card
                tw="grid grid-cols-3 gap-y-4 items-center relative"
                css={
                  selectedPackage.package === Packages.Medium
                    ? [tw`border-violet-500 border`]
                    : []
                }
              >
                {selectedPackage.package === Packages.Medium && (
                  <div tw="badge badge-primary absolute -top-2 right-4">
                    {locale.packages.favorite}
                  </div>
                )}
                <Text tw="text-lg lg:text-xl font-semibold row-start-1 col-span-2">
                  {selectedPackage.name}
                </Text>
                <Text tw="italic text-sm col-span-2" variant="sub">
                  {selectedPackage.service.map((service) => (
                    <>
                      <span key={service}>{service}</span>
                      <br />
                    </>
                  ))}
                </Text>

                <Text tw="row-start-1">
                  <span tw="font-semibold text-base lg:text-xl">
                    {selectedPackage.price}
                  </span>{' '}
                  <span tw="opacity-80">{locale.packages.chargeRate}</span>
                </Text>

                <Button
                  tw="w-full"
                  variant="primary"
                  type="button"
                  onClick={() => onSelectedPackage(selectedPackage.package)}
                >
                  {locale.packages.select}
                </Button>
              </Card>
            </li>
          );
        })}
      </ul>
      <Button as={Link} href={'/#packages'} variant="link" tw="p-0 text-left">
        {locale.packages.subTitle}
      </Button>
    </>
  );
};
