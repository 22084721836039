/** @jsxImportSource @emotion/react */

import { Button, Form, Input, Text } from '@sal-solution/ui-web';
import { toast } from 'react-toastify';
import 'twin.macro';
import tw from 'twin.macro';
import { useLocale } from '../../../../hooks/useLocal';
import { useFindMeQuery } from './findUser.generated';
import { useUpdateUserMutation } from './updateUser.generated';
import { localeEN, localeNL } from '../../../../utils/locale';

export const Profile = () => {
  const locale = useLocale(locales);
  const {
    data,
    error: errorFindMe,
    loading: loadingFindMe,
    refetch,
  } = useFindMeQuery();
  const [
    updateUser,
    { loading: loadingUserMutation, error: errorUserMutation },
  ] = useUpdateUserMutation();

  const error = errorFindMe || errorUserMutation;
  const loading = loadingFindMe || loadingUserMutation;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const name = form.get('name') as string;
    const email = form.get('email') as string;
    const tel = form.get('tel') as string;
    const response = await updateUser({
      variables: {
        input: { name, email, tel },
      },
    });

    if (response.data) {
      await refetch();
      toast.success('Profile updated');
    }
  };

  const onPasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const currentPassword = form.get('currentPassword') as string;
    const newPassword = form.get('newPassword') as string;
    const confirmNewPassword = form.get('confirmNewPassword') as string;

    if (newPassword !== confirmNewPassword) {
      return toast.error('Passwords do not match');
    }

    const response = await updateUser({
      variables: {
        input: { currentPassword, newPassword },
      },
    });

    if (response.data) {
      toast.success('Password changed successfully');
    }
  };
  return (
    <div>
      {error && (
        <div role="alert" tw="alert alert-error w-full text-white mb-4">
          <button onClick={() => refetch()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              tw="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <span>{error.message}</span>
        </div>
      )}
      <Text as="h1" variant="title">
        {locale.title}
      </Text>
      <Form
        tw="lg:(grid grid-cols-2 gap-4) space-y-4 mb-8 w-full"
        onSubmit={onSubmit}
      >
        <Input
          name="name"
          label={locale.name}
          defaultValue={data?.findMe?.name}
        />
        <Input
          name="email"
          label={locale.email}
          defaultValue={data?.findMe?.email}
        />
        <Input
          name="tel"
          label={locale.tel}
          defaultValue={data?.findMe?.tel}
          containerCSS={tw`lg:(row-start-2 col-span-3)`}
        />
        <Button tw="lg:(row-start-3 col-span-3)">
          {loading && <span tw="loading loading-spinner loading-md"></span>}
          {locale.update}
        </Button>
      </Form>
      <Form tw="space-y-4" onSubmit={onPasswordChange}>
        <Input
          name="currentPassword"
          label={locale.currentPassword}
          type="password"
        />
        <Input name="newPassword" label={locale.newPassword} type="password" />
        <Input
          name="confirmNewPassword"
          label={locale.confirmNewPassword}
          type="password"
        />
        <Button>{locale.changePassword}</Button>
      </Form>
    </div>
  );
};

// create object with locales for english and dutch
const locales = {
  [localeEN]: {
    title: 'Your Profile',
    name: 'Name',
    email: 'Email',
    tel: 'Phone',
    update: 'Update',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
  },
  [localeNL]: {
    title: 'Uw Profiel',
    name: 'Naam',
    email: 'Email',
    tel: 'Telefoon',
    update: 'Bijwerken',
    changePassword: 'Wachtwoord wijzigen',
    currentPassword: 'Huidig wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    confirmNewPassword: 'Bevestig nieuw wachtwoord',
  },
};
