import * as Types from '@sal-solution/types/types/housing/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivateSubscriptionMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CreateCheckoutUrlInput>;
}>;


export type ActivateSubscriptionMutation = { __typename?: 'Mutation', createCheckoutUrl: string };


export const ActivateSubscriptionDocument = gql`
    mutation activateSubscription($input: CreateCheckoutUrlInput) {
  createCheckoutUrl(input: $input)
}
    `;
export type ActivateSubscriptionMutationFn = Apollo.MutationFunction<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;

/**
 * __useActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSubscriptionMutation, { data, loading, error }] = useActivateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>(ActivateSubscriptionDocument, options);
      }
export type ActivateSubscriptionMutationHookResult = ReturnType<typeof useActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationResult = Apollo.MutationResult<ActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;