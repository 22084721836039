/** @jsxImportSource @emotion/react */

import { localeEN, localeNL } from '@/utils/locale';
import 'twin.macro';
import { Invoices } from './components/Invoices';
import { SubscriptionInfo } from './components/SubscriptionInfo';
import { UpdateSubscription } from './components/UpdateSubscription';

export const Subscription = () => {
  return (
    <div tw="w-full">
      <SubscriptionInfo />
      <UpdateSubscription />
      <hr tw="my-4" />
      <Invoices />
    </div>
  );
};

// create locales object for en-en and nl-nl for subscription page
export const subscriptionLocales = {
  [localeEN]: {
    confirmCancelSubscription:
      'Are you sure you want to cancel the subscription?',
    yourPackage: 'Your package',
    whatsAppMessagesSend: 'WhatsApp messages send',
    title: 'Subscription',
    status: 'Status',
    since: 'Since',
    endsOn: 'Ends on',
    cancel: 'Cancel',
    activate: 'Activate',
    invoices: 'Invoices',
    amount: 'Amount',
    paid: 'Paid',
    chargedBack: 'Charged back',
    refunded: 'Refunded',
    createdAt: 'Created at',
    actions: 'Actions',
    pay: 'Pay',
  },
  [localeNL]: {
    confirmCancelSubscription:
      'Weet je zeker dat je het abonnement wilt annuleren?',
    yourPackage: 'Je pakket',
    whatsAppMessagesSend: 'WhatsApp berichten verzonden',
    title: 'Abonnement',
    status: 'Status',
    since: 'Sinds',
    endsOn: 'Eindigt op',
    cancel: 'Annuleren',
    activate: 'Activeren',
    invoices: 'Facturen',
    amount: 'Bedrag',
    paid: 'Betaald',
    chargedBack: 'Teruggeboekt',
    refunded: 'Terugbetaald',
    createdAt: 'Aangemaakt op',
    actions: 'Acties',
    pay: 'Betalen',
  },
};
