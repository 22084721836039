/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { Button, Text } from '@sal-solution/ui-web';
import { useActivateSubscriptionMutation } from '../activateSubscription.generated';
import { useCancelSubscriptionMutation } from '../cancelSubscription.generated';
import { useSubscribe } from '@/contexts/Subscribe.context';
import { toast } from 'react-toastify';
import { useSubscriptionQuery } from '../subscription.generated';
import { SelectPackage } from '@/components/SelectPackage/SelectPackage';
import { subscriptionLocales } from '../Subscription';
import { useLocale } from '@/hooks/useLocal';

export const UpdateSubscription = () => {
  const { userPackage } = useSubscribe();
  const locale = useLocale(subscriptionLocales);

  const {
    data,
    loading: subscriptionLoading,
    error: subscriptionError,
    refetch,
  } = useSubscriptionQuery();
  const [activeSubscription, activeSubscriptionResponse] =
    useActivateSubscriptionMutation();
  const [cancelSubscription, cancelSubscriptionResponse] =
    useCancelSubscriptionMutation();

  const onActivateSubscription = async () => {
    try {
      const response = await activeSubscription({
        variables: {
          input: {
            userPackage,
          },
        },
      });

      if (response.data?.createCheckoutUrl)
        window.open(response.data.createCheckoutUrl, '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  const onCancelSubscription = async () => {
    try {
      const confirm = window.confirm(locale.confirmCancelSubscription);
      if (!confirm) return;
      const response = await cancelSubscription();
      console.log(response.data?.cancelSubscription);
      if (response.data?.cancelSubscription) {
        toast.success('Subscription canceled successfully');
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {' '}
      {data?.findMe?.subscription.isActive ? (
        <Button onClick={onCancelSubscription}>{locale.cancel}</Button>
      ) : (
        <div tw="grid">
          <SelectPackage />
          {userPackage && (
            <Button onClick={onActivateSubscription}>{locale.activate}</Button>
          )}
        </div>
      )}
    </>
  );
};
