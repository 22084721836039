import { localeEN, localeNL } from '../../../utils/locale';

export const homePageLocale = {
  [localeEN]: {
    pageTitle: 'Houses in Amsterdam',
    title: 'Find your  rental home faster than ever!',
    subTitle: 'Try it out for free!',
    description:
      'Receive real-time home updates on WhatsApp + mail and stay ahead in the home hunting Game',
    search: 'Search',
    notify: 'Start now!',
    houses: 'Latest Houses',
    howItWorks: 'How it works',
    packages: 'Our packages',
    reviews: 'Our reviews',
    deal: 'Try it out for free!',
    step1: 'Step 1',
    step2: 'Step 2',
    step3: 'Step 3',
    step2Description: 'Create an account',
    step2Explanation: 'Let us know where we can send our messages',
    step2Highlight: 'messages',
    step1Description: 'Fill in your housing preferences',
    step1Explanation: 'Let us know what kind of house you are looking for',
    step1Highlight: 'housing',
    step3Description: 'Done! You will now receive real-time updates.',
    step3Explanation:
      'Throughout the day you will receive updates on new houses available',
    step3Highlight: 'updates',
    subscribe: 'Subscribe Now!',
    subscribeSection: {
      title: 'Become a subscriber today!',
      description:
        'Don’t want to miss your next home? Sign up and receive 24/7 real-time updates!',
      cta: 'Get started!',
    },
    first: 'Be the first to respond!',
    appartmentCard: {
      title: 'New appartment found!',
      description: 'Be the first to respond!',
      cta: 'Visit now!',
      rooms: 'Rooms',
      newHouseFound: '1 new house found!',
      justNow: 'Just now',
      delivered: 'Delivered',
    },
    salesCard: {
      title: 'Start now for only €9,99 per month!',
      description:
        'Subscribe now and receive the latest updates on new houses available for rent in your city.',
      cta: 'Subscribe now!',
    },
    stats: {
      title: 'Statistics',
      houses: {
        title: 'Houses',
        description: 'Total number of houses',
      },
      averagePrice: {
        title: 'Average Price',
        description: 'Average price of a house',
      },
      averageHousesPerDay: {
        title: 'Average Houses Per Day',
        description: 'Average number of houses found per day',
      },
      totalUsers: {
        title: "We've helped",
        description: 'Users so far!',
      },
    },
  },
  [localeNL]: {
    pageTitle: 'Huizen in Amsterdam',
    title: 'Wil jij geen huurwoning meer missen?',
    subTitle: 'Probeer het gratis uit!',
    description:
      'Ontvang real-time woningupdates op WhatsApp + mail en blijf voorop bij het huurwoningen-jacht',
    search: 'Start nu!',
    notify: 'Start nu!',
    houses: 'Het nieuwste aanbod',
    howItWorks: 'Hoe het werkt',
    packages: 'Onze pakketten',
    reviews: 'Onze reviews',
    deal: 'Probeer het gratis uit!',
    step1: 'Stap 1',
    step2: 'Stap 2',
    step3: 'Stap 3',
    step2Description: 'Maak een account aan',
    step2Explanation:
      'Laat ons weten naar waar wij onze berichten mogen sturen',
    step2Highlight: 'berichten',
    step1Description: 'Vul jouw woonwensen in',
    step1Explanation: 'Laat ons weten naar wat voor woning je op zoek bent',
    step1Highlight: 'woning',
    step3Description: 'Done! Je ontvangt vanaf nu real-time updates.',
    step3Explanation:
      'Gedurdende dag krijgt je updates over nieuwe woningen die beschikbaar zijn',
    step3Highlight: 'updates',
    subscribe: 'Abonneer nu!',
    subscribeSection: {
      title: 'Word vandaag nog abonnee!',
      description:
        'Wil je je volgende woning niet meer missen? Meld je aan en ontvang 24/7 realtime updates!',
      cta: 'Aan de slag!',
    },
    first: 'Wees de eerste om te reageren!',
    appartmentCard: {
      title: 'Nieuw appartement gevonden!',
      description: 'Wees de eerste om te reageren!',
      cta: 'Bezoek nu!',
      rooms: 'Kamers',
      newHouseFound: '1 nieuw appartement gevonden!',
      justNow: 'Zojuist',
      delivered: 'Bezorgd',
    },
    salesCard: {
      title: 'Begin nu voor slechts €9,99 per maand!',
      description:
        'Abonneer u nu en ontvang de nieuwste updates over nieuwe huizen die beschikbaar zijn voor verhuur in uw stad.',
      cta: 'Abonneer nu!',
    },
    stats: {
      title: 'Statistieken',
      houses: {
        title: 'Tot zo ver',
        description: 'huizen gevonden',
      },
      averagePrice: {
        title: 'De gemiddelde huurprijs',
        description: 'Op basis van de gevonden huizen',
      },
      averageHousesPerDay: {
        title: 'Wij vinden gemiddeld',
        description: 'woningen per dag!',
      },
      totalUsers: {
        title: 'Geholpen gebruikers',
        description: 'En laat ons jou ook helpen!',
      },
    },
  },
} as const;
